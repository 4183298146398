// import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice } from "@reduxjs/toolkit";
// import {USER_DATA} from '../../../utils/localStoreConstents';
import {
  GetIAPSubscriptionReducerState,
  LoginReducerState,
} from "./signin.types";
import {
  getIAPSubscriptionMiddleWare,
  loginMiddleWare,
} from "./signinMiddleware";

const userInitialState: any = {
  isLoader: false,
  data: {},
};

export const userReducer = createSlice({
  name: "user_data",
  initialState: userInitialState,
  reducers: {
    logIn: (state, action) => {
      // AsyncStorage.setItem(USER_DATA, JSON.stringify({...action.payload}));
      state.isLoader = false;
      state.data = action.payload;
    },
    logOut: (state, action) => {
      localStorage.clear();
      // AsyncStorage.removeItem(USER_DATA);
      state.isLoader = false;
      state.data = action.payload;
    },
  },
});

const getCommentsInitialState: LoginReducerState = {
  isLoading: false,
  error: "",
  metadata: {
    userId: "",
  },
};

const loginReducer = createSlice({
  name: "login",
  initialState: getCommentsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginMiddleWare.pending, (state) => {
      state.metadata = { userId: "" };
    });
    builder.addCase(loginMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.metadata = action.payload?.metadata;
    });
    builder.addCase(loginMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

const getIAPSubscriptionInitialState: GetIAPSubscriptionReducerState = {
  isLoading: false,
  error: "",
};

const getIAPSubscriptionReducer = createSlice({
  name: "get-iap-subscription",
  initialState: getIAPSubscriptionInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIAPSubscriptionMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getIAPSubscriptionMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getIAPSubscriptionMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

export const { logIn, logOut } = userReducer.actions;
export const userReducers = userReducer.reducer;
export const loginReducers = loginReducer.reducer;
export const getIAPSubscriptionReducers = getIAPSubscriptionReducer.reducer;
