import { memo } from "react";

const defaultProps = {
  width: 16,
  height: 18,
};

const SvgUpload = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 12 14" fill="none">
    <path
      d="M3.75 9.98828V5.48828H0.761719L6 0.25L11.2383 5.48828H8.25V9.98828H3.75ZM6 2.35938L4.38281 4.01172H5.26172V8.51172H6.73828V4.01172H7.61719L6 2.35938ZM0.761719 11.5V13.0117H11.2383V11.5H0.761719Z"
      fill="url(#paint0_linear_4301_14640)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4301_14640"
        x1="15"
        y1="-2"
        x2="-3.43913"
        y2="0.463211"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#35D7F3" />
        <stop offset="1" stopColor="#116B7A" />
      </linearGradient>
    </defs>
  </svg>
);

SvgUpload.defaultProps = defaultProps;

export default memo(SvgUpload);
