import { FormHelperText } from "@mui/material";
import { FONTS } from "../../utils/fontConstant";

type Props = {
  error: any;
  id?: string;
};

const ErrorMessage = ({ error, id = "accountId-error" }: Props) => {
  return (
    <FormHelperText
      sx={{ fontFamily: FONTS.SOURCESANSPRO_SEMIBOLD, fontSize: 12 }}
      error
      id={id}
    >
      {error}
    </FormHelperText>
  );
};

export default ErrorMessage;
