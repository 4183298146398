import { memo } from "react";

const defaultProps = {
  width: 44,
  height: 44,
};

const SvgAdd = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="22" fill="url(#paint0_linear_961_7230)" />
    <path d="M14 22H30" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M22 14L22 30"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_961_7230"
        x1="44"
        y1="9.81618e-06"
        x2="-1.07343"
        y2="6.02118"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#35D7F3" />
        <stop offset="1" stopColor="#116B7A" />
      </linearGradient>
    </defs>
  </svg>
);

SvgAdd.defaultProps = defaultProps;

export default memo(SvgAdd);
