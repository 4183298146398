import { createSlice } from "@reduxjs/toolkit";
import { ZoomIDReducerState, ZoomListReducerState } from "./askthedoc.types";
import {
  getZoomIDMiddleWare,
  getZoomListMiddleWare,
} from "./askthedocMiddleware";

const zoomListInitialState: ZoomListReducerState = {
  isLoading: false,
  error: "",
  data: {
    totalSessions: 0,
    page: 0,
    pagesize: 0,
  },
};

const getZoomListReducer = createSlice({
  name: "zoom_list",
  initialState: zoomListInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getZoomListMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getZoomListMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getZoomListMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

const getZoomIDInitialState: ZoomIDReducerState = {
  isLoading: false,
  error: "",
};

const getZoomIDReducer = createSlice({
  name: "zoom_ID",
  initialState: getZoomIDInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getZoomIDMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getZoomIDMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getZoomIDMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

export const getZoomListReducers = getZoomListReducer.reducer;
export const getZoomIDReducerReducers = getZoomIDReducer.reducer;
