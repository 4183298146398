import { SxProps, Theme } from "@mui/material";
import {
  DatePicker as DatePickerMUI,
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import styles from "./datepicker.module.scss";
import LabelWrapper from "../LabelWrapper/LabelWrapper";

type Props = {
  label?: string;
  labelColor?: string;
  labelSize?: number;
  onChange?: (
    value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => void;
  value?: any;
  sx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  labelClassName?: string;
  required?: boolean;
  disabled?: boolean;
};

const DatePicker = ({
  label,
  labelSize,
  labelColor,
  onChange,
  value,
  sx,
  labelClassName,
  labelSx,
  required,
  disabled,
}: Props) => {
  return (
    <LabelWrapper
      required={required}
      labelSx={labelSx}
      labelClassName={labelClassName}
      label={label}
      labelColor={labelColor}
      labelSize={labelSize}
    >
      <DatePickerMUI
        className={styles.datePicker}
        onChange={onChange}
        value={value}
        disabled={disabled}
        sx={{ width: "100%", ...sx }}
      />
    </LabelWrapper>
  );
};

export default DatePicker;
