import { Box, CircularProgress, SxProps, Theme } from "@mui/material";
import { useState } from "react";
import styles from "./vimeovideoonly.module.scss";

type Props = {
  src: string;
  height?: number;
  width?: number | string;
  sxContainer?: SxProps<Theme>;
};

const VimeoVideoOnly = ({
  src,
  height = 260,
  width = 400,
  sxContainer,
}: Props) => {
  const [isLoader, setLoader] = useState(true);
  return (
    <Box
      className={styles.overAll}
      sx={{ position: "relative", ...sxContainer }}
    >
      <Box
        sx={{
          position: "relative",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {isLoader && (
          <Box sx={{ position: "absolute", zIndex: 111 }}>
            <CircularProgress
              sx={{ height: 100, width: 100 }}
              color="inherit"
            />
          </Box>
        )}
        <iframe
          className={styles.videoIframe}
          onLoad={() => setLoader(false)}
          src={src}
          height={height}
          width={width}
        />
      </Box>
    </Box>
  );
};

export default VimeoVideoOnly;
