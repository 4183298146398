import { createTheme } from "@mui/material";
import {
  BG_SHADES,
  BG_SHADES_DARK,
  BG_SHADES_DARKER,
  BG_SHADES_DARKEST,
  BG_SHADES_LIGHT,
  BG_SHADES_LIGHTER,
  BG_SHADES_LIGHTEST,
  ERROR,
  GREY_TEXT_1,
  GREY_TEXT_2,
  PRIMARY,
  SECONDARY,
  SUCCESS,
  WARNING,
} from "./colors";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xll: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1200,
      xl: 1500,
      xll: 1900,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      dark: PRIMARY,
      main: PRIMARY,
      light: PRIMARY,
      contrastText: "#ffffff",
    },
    secondary: {
      dark: SECONDARY,
      main: SECONDARY,
      light: SECONDARY,
      contrastText: "#000000",
    },
    error: {
      dark: ERROR,
      main: ERROR,
      light: ERROR,
      contrastText: "#ffffff",
    },
    warning: {
      dark: WARNING,
      main: WARNING,
      light: WARNING,
      contrastText: "#ffffff",
    },
    success: {
      dark: SUCCESS,
      main: SUCCESS,
      light: SUCCESS,
      contrastText: "#ffffff",
    },
    grey: {
      "50": BG_SHADES_LIGHTEST,
      "100": BG_SHADES_LIGHTER,
      "200": BG_SHADES_LIGHT,
      "300": BG_SHADES,
      "400": BG_SHADES_DARK,
      "500": BG_SHADES_DARKER,
      "600": BG_SHADES_DARKEST,
      "700": GREY_TEXT_1,
      "800": GREY_TEXT_2,
      // "900": TRANSPARENT,
    },
    text: {
      primary: "#ffffff",
      secondary: "#acacac",
      disabled: "#999999",
    },
  },
  typography: {
    fontFamily: '"Montserrat-Regular"',
    h1: {
      fontFamily: '"Montserrat-Regular"',
    },
    h2: {
      fontFamily: '"Montserrat-Regular"',
    },
    h3: {
      fontFamily: '"Montserrat-Regular"',
    },
    h4: {
      fontFamily: '"Montserrat-Regular"',
    },
    h5: {
      fontFamily: '"Montserrat-Regular"',
    },
    h6: {
      fontFamily: '"Montserrat-Regular"',
    },
    subtitle1: {
      fontFamily: '"Montserrat-Bold"',
    },
    subtitle2: {
      fontFamily: '"Montserrat-Bold"',
    },
    body1: {
      fontFamily: '"Montserrat-Regular"',
    },
    body2: {
      fontFamily: '"Montserrat-Regular"',
    },
    button: {
      fontFamily: '"Montserrat-SemiBold"',
    },
    caption: {
      fontFamily: '"Montserrat-Regular"',
    },
    overline: {
      fontFamily: '"Montserrat-Regular"',
    },
  },
});

export default theme;
