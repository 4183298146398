import { memo } from "react";

const defaultProps = {
  width: 44,
  height: 44,
};

const SvgRoundEdit = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="22" fill="url(#paint0_linear_778_5614)" />
    <path
      d="M26.6158 14.5806C26.9876 14.2089 27.4918 14 28.0176 14C28.2779 14 28.5357 14.0513 28.7762 14.1509C29.0168 14.2505 29.2353 14.3965 29.4194 14.5806C29.6035 14.7647 29.7495 14.9832 29.8491 15.2238C29.9487 15.4643 30 15.7221 30 15.9824C30 16.2427 29.9487 16.5005 29.8491 16.741C29.7495 16.9815 29.6035 17.2001 29.4194 17.3842L18.5215 28.2821C18.0088 28.7947 17.3665 29.1584 16.6632 29.3342L14 30L14.6658 27.3368C14.8416 26.6335 15.2053 25.9912 15.7179 25.4785L26.6158 14.5806Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 17L27 20"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M23 30L30 30"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_778_5614"
        x1="44"
        y1="9.81618e-06"
        x2="-1.07343"
        y2="6.02118"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#35D7F3" />
        <stop offset="1" stopColor="#116B7A" />
      </linearGradient>
    </defs>
  </svg>
);

SvgRoundEdit.defaultProps = defaultProps;

export default memo(SvgRoundEdit);
