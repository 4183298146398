import { memo } from "react";

const defaultProps = {
  width: 10,
  height: 18,
};

const SvgFB = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 10 18" fill="none">
    <path
      d="M6.39703 17.9972V9.80115H9.16203L9.57303 6.59215H6.39703V4.54815C6.39703 3.62215 6.65503 2.98815 7.98403 2.98815H9.66803V0.12715C8.84903 0.03915 8.02503 -0.00285004 7.20103 0.000149963C4.75703 0.000149963 3.07903 1.49215 3.07903 4.23115V6.58615H0.332031V9.79515H3.08503V17.9972H6.39703Z"
      fill="#A5A5A5"
    />
  </svg>
);

SvgFB.defaultProps = defaultProps;

export default memo(SvgFB);
