import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 25,
};

const SvgPratice = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 24 25" fill="none">
    <path
      d="M5.24717 18.8654L3 21.1126M5.24717 18.8654L6.66225 20.2908C7.10104 20.7327 7.81246 20.7327 8.25124 20.2908L19.7695 9.03566M5.24717 18.8654L3.63945 17.246C3.20066 16.8041 3.20066 16.0875 3.63945 15.6455L5.94626 13.3293M20 4.1378L17.4543 6.70377M20 4.1378L18.9911 3.12891M20 4.1378L21 5.1378M17.4543 6.70377L19.7695 9.03566M17.4543 6.70377L15.0025 4.23411M10.5029 12.1377L8.81346 10.4483M8.81346 10.4483L12.1895 7.05849M8.81346 10.4483L5.94626 13.3293M15.0025 4.23411L13.8973 3.12891M15.0025 4.23411L12.1895 7.05849M19.7695 9.03566L21 10.2662M7.59877 14.9818L5.94626 13.3293M13.9159 8.78481L12.1895 7.05849"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

SvgPratice.defaultProps = defaultProps;

export default memo(SvgPratice);
