import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
};

const SvgCalender = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 16 17" fill="none">
    <path
      d="M11.5 3.5H11V3C11 2.86739 10.9473 2.74021 10.8536 2.64645C10.7598 2.55268 10.6326 2.5 10.5 2.5C10.3674 2.5 10.2402 2.55268 10.1464 2.64645C10.0527 2.74021 10 2.86739 10 3V3.5H6V3C6 2.86739 5.94732 2.74021 5.85355 2.64645C5.75979 2.55268 5.63261 2.5 5.5 2.5C5.36739 2.5 5.24021 2.55268 5.14645 2.64645C5.05268 2.74021 5 2.86739 5 3V3.5H4.5C3.8372 3.50079 3.20178 3.76444 2.73311 4.23311C2.26444 4.70178 2.00079 5.3372 2 6L2 12C2.00079 12.6628 2.26444 13.2982 2.73311 13.7669C3.20178 14.2356 3.8372 14.4992 4.5 14.5H11.5C12.1628 14.4992 12.7982 14.2356 13.2669 13.7669C13.7356 13.2982 13.9992 12.6628 14 12V6C13.9992 5.3372 13.7356 4.70178 13.2669 4.23311C12.7982 3.76444 12.1628 3.50079 11.5 3.5V3.5ZM3 6C3 5.60218 3.15804 5.22064 3.43934 4.93934C3.72064 4.65804 4.10218 4.5 4.5 4.5H11.5C11.8978 4.5 12.2794 4.65804 12.5607 4.93934C12.842 5.22064 13 5.60218 13 6V6.5H3V6ZM11.5 13.5H4.5C4.10218 13.5 3.72064 13.342 3.43934 13.0607C3.15804 12.7794 3 12.3978 3 12V7.5H13V12C13 12.3978 12.842 12.7794 12.5607 13.0607C12.2794 13.342 11.8978 13.5 11.5 13.5Z"
      fill="#6B6A73"
    />
    <path
      d="M8.00004 10.4998C8.36823 10.4998 8.66671 10.2014 8.66671 9.83317C8.66671 9.46498 8.36823 9.1665 8.00004 9.1665C7.63185 9.1665 7.33337 9.46498 7.33337 9.83317C7.33337 10.2014 7.63185 10.4998 8.00004 10.4998Z"
      fill="#6B6A73"
    />
    <path
      d="M5.33329 10.4998C5.70148 10.4998 5.99996 10.2014 5.99996 9.83317C5.99996 9.46498 5.70148 9.1665 5.33329 9.1665C4.9651 9.1665 4.66663 9.46498 4.66663 9.83317C4.66663 10.2014 4.9651 10.4998 5.33329 10.4998Z"
      fill="#6B6A73"
    />
    <path
      d="M10.6667 10.4998C11.0349 10.4998 11.3333 10.2014 11.3333 9.83317C11.3333 9.46498 11.0349 9.1665 10.6667 9.1665C10.2985 9.1665 10 9.46498 10 9.83317C10 10.2014 10.2985 10.4998 10.6667 10.4998Z"
      fill="#6B6A73"
    />
  </svg>
);

SvgCalender.defaultProps = defaultProps;

export default memo(SvgCalender);
