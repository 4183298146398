import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
  fill: "#50BDB2",
  focused: false,
};

const SvgWorld = ({ width, height, fill, focused }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <g opacity={focused ? 1 : 0.5}>
      <path
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke={fill}
        strokeWidth="2"
      />
      <path
        d="M15 12C15 16.9706 13.6569 21 12 21C10.3431 21 9 16.9706 9 12C9 7.02944 10.3431 3 12 3C13.6569 3 15 7.02944 15 12Z"
        stroke={fill}
        strokeWidth="2"
      />
      <path
        d="M12 15C7.02944 15 3 13.6569 3 12C3 10.3431 7.02944 9 12 9C16.9706 9 21 10.3431 21 12C21 13.6569 16.9706 15 12 15Z"
        stroke={fill}
        strokeWidth="2"
      />
    </g>
  </svg>
);

SvgWorld.defaultProps = defaultProps;

export default memo(SvgWorld);
