import { memo } from "react";

const defaultProps = {
  width: 26,
  height: 27,
};

const SvgHomeRadio = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 26 27" fill="none">
    <circle opacity="0.1" cx="13" cy="13.5" r="13" fill="white" />
    <circle cx="13" cy="14" r="5" fill="url(#paint0_linear_1680_12844)" />
    <defs>
      <linearGradient
        id="paint0_linear_1680_12844"
        x1="18"
        y1="9"
        x2="7.75604"
        y2="10.3685"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#35D7F3" />
        <stop offset="1" stopColor="#116B7A" />
      </linearGradient>
    </defs>
  </svg>
);

SvgHomeRadio.defaultProps = defaultProps;

export default memo(SvgHomeRadio);
