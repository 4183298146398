import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import {
  getHistoryPhysicalMiddleWare,
  updateHistoryPhysicalMiddleWare,
} from "./store/historyPhysicalMiddleware";
import { setLoader } from "../../common/store/commonReducer";
import { HealthAndPhysicalExamV1Logs } from "./store/historyPhysicalTypes";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routesPath";
import { Typography } from "@mui/material";
import HPContainer from "../../common/HPContainer";
import { FONTS } from "../../utils/fontConstant";
import { GREY_TEXT_1, PRIMARY, WHITE } from "../../theme/colors";
import { CheckBox } from "../../packages";

function convertToCamelCase(input: string) {
  if (input === routes.HISTORY_PHYSICAL_22A.replace("/", "")) {
    return "HistoryPhysical22A";
  } else if (input === routes.HISTORY_PHYSICAL_17A.replace("/", "")) {
    return "HistoryPhysical17A";
  } else {
    return input
      .split("-")
      .map((word, index) => {
        if (index === 0) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
      })
      .join("");
  }
}

export const handleHistoryPhysicalPost = ({
  dispatch,
  healthAndPhysicalExamV1Logs,
  isCheck,
  navigation,
  page,
  currentPage,
  navigate,
}: {
  dispatch: any;
  healthAndPhysicalExamV1Logs: any;
  isCheck: any;
  navigation: any;
  page: string;
  currentPage: string;
  navigate: string;
}) => {
  const getPage = convertToCamelCase(page.replace("/", ""));
  dispatch(setLoader(true));
  const filterValue = healthAndPhysicalExamV1Logs?.pageDetails?.filter(
    (list: any) => list?.otherDetails?.page !== getPage
  );

  const submitArray = [
    {
      openedAt: new Date().toISOString(),
      otherDetails: {
        page: getPage,
        value: isCheck,
      },
    },
  ];

  dispatch(
    updateHistoryPhysicalMiddleWare({
      currentPage,
      pageDetails: [...filterValue, ...submitArray],
    })
  ).finally(() => {
    dispatch(setLoader(false));
    navigation(navigate);
  });
};

const mapStateToProps = (state: RootState) => {
  const { getHistoryPhysicalReducers } = state;
  return {
    healthAndPhysicalExamV1Logs:
      getHistoryPhysicalReducers.data?.healthAndPhysicalExamV1Logs,
  };
};

type Props = {
  healthAndPhysicalExamV1Logs?: HealthAndPhysicalExamV1Logs;
};

const HistoryPhysical1 = ({ healthAndPhysicalExamV1Logs }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isAgree, setAgree] = useState(false);

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(getHistoryPhysicalMiddleWare()).then(() =>
      dispatch(setLoader(false))
    );
  }, []);
  useEffect(() => {
    if (healthAndPhysicalExamV1Logs?.questionAndAnswers?.isAgreement) {
      setAgree(true);
    } else {
      setAgree(false);
    }
  }, [healthAndPhysicalExamV1Logs?.questionAndAnswers?.isAgreement]);

  const handleSubmit = () => {
    dispatch(setLoader(true));
    dispatch(
      updateHistoryPhysicalMiddleWare({
        currentPage: "2",
        pageDetails: healthAndPhysicalExamV1Logs?.pageDetails,
        questionAndAnswers: {
          isAgreement: isAgree,
        },
      })
    ).finally(() => {
      dispatch(getHistoryPhysicalMiddleWare()).finally(() => {
        dispatch(setLoader(false));
        navigate(routes.HISTORY_PHYSICAL_2);
      });
    });
  };

  return (
    <HPContainer
      disabledSubmit={!isAgree}
      title="History & Physical Exam"
      handleSubmit={handleSubmit}
    >
      <Typography
        display="inline"
        sx={{ whiteSpace: "pre-wrap" }}
        color={WHITE}
        fontSize={16}
        fontFamily={FONTS.SOURCESANSPRO_REGULAR}
      >
        Welcome to the H & P Knowledge Base (Scroll down to click Agreement)
      </Typography>
      <Typography
        component={"pre"}
        sx={{ whiteSpace: "pre-wrap" }}
        color={GREY_TEXT_1}
        fontSize={16}
        fontFamily={FONTS.SOURCESANSPRO_REGULAR}
      >
        <br />
        This will take anywhere from a few minutes to several hours, depending
        on your feedback.
        <br />
        <br />
      </Typography>
      <Typography
        sx={{ whiteSpace: "pre-wrap" }}
        color={GREY_TEXT_1}
        fontSize={16}
        fontFamily={FONTS.SOURCESANSPRO_REGULAR}
      >
        As you navigate through the History and Physical, you will be informed
        about the importance of{" "}
        <Typography
          display="inline"
          sx={{ whiteSpace: "pre-wrap" }}
          color={WHITE}
          fontSize={16}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
        >
          labs and studies
        </Typography>
        , your{" "}
        <Typography
          display="inline"
          sx={{ whiteSpace: "pre-wrap" }}
          color={WHITE}
          fontSize={16}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
        >
          medical and family history
        </Typography>
        , vital signs, analysis of your{" "}
        <Typography
          display="inline"
          sx={{ whiteSpace: "pre-wrap" }}
          color={WHITE}
          fontSize={16}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
        >
          medical allergies
        </Typography>{" "}
        and the medications you take, and your{" "}
        <Typography
          display="inline"
          sx={{ whiteSpace: "pre-wrap" }}
          color={WHITE}
          fontSize={16}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
        >
          psychosocial history
        </Typography>{" "}
        in evaluating your medical issues.
        <br />
        <br />
        Furthermore, it will take you through an overview of common and relevant
        medical issues and topics for men such as:
        <br />
        <br />
        As discussed in the Medical Disclaimer (link at the bottom of every
        page), this History and Physical exam{" "}
        <Typography
          display="inline"
          sx={{ whiteSpace: "pre-wrap" }}
          color={WHITE}
          fontSize={16}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
        >
          does not
        </Typography>{" "}
        create a doctor-patient relationship. Please discuss all your{" "}
        <Typography
          display="inline"
          sx={{ whiteSpace: "pre-wrap" }}
          color={WHITE}
          fontSize={16}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
        >
          health
        </Typography>{" "}
        concerns and{" "}
        <Typography
          display="inline"
          sx={{ whiteSpace: "pre-wrap" }}
          color={WHITE}
          fontSize={16}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
        >
          issues
        </Typography>{" "}
        with your doctor and regularly see your doctor for check-ups. This
        content is for information purposes only.
        <br />
        <br />
        Some of the content in this History and Physical exam discusses or
        references the use of anabolic steroids, which are illegal in many
        countries, including the United States. We do not endorse the use of
        anabolic steroids either medically or legally. In fact, we encourage you
        to not use anabolic steroids!
        <br />
        <br />
        As discussed in the Privacy Policy (
        <Typography
          display="inline"
          sx={{ whiteSpace: "pre-wrap" }}
          color={PRIMARY}
          fontSize={16}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
        >
          link at the bottom of every page
        </Typography>
        ), this App does not collect your medical data, such as lab results or
        results of studies. Rather, it conveys information a doctor collects
        when giving a thorough health and physical exam, and let's you navigate
        the content with this knowledge.
        <br />
        <br />
        If after completing this exam you have further questions, check out the
        other content on the App, like the library of Man-To-Man episodes or ask
        your question to Dr. O'Connor directly via Man-To-Man.
        <br />
        <br />
      </Typography>
      <Typography
        sx={{ whiteSpace: "pre-wrap" }}
        color={WHITE}
        fontSize={16}
        fontFamily={FONTS.MONTSERRAT_SEMIBOLD}
      >
        Medical Disclaimer
        <br />
        <br />
      </Typography>
      <CheckBox
        checked={isAgree}
        onClick={() => {
          setAgree(!isAgree);
        }}
        label="Agreement"
      />
    </HPContainer>
  );
};

export default connect(mapStateToProps)(HistoryPhysical1);
