import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
};

const SvgHelp = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_743_6086)">
      <circle
        cx="12"
        cy="12"
        r="9"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="12"
        y="16"
        width="0.01"
        height="0.01"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M10.5858 7.58609C10.9754 7.19646 11.4858 7.0012 11.9965 7.00031C12.5095 6.99941 13.0228 7.19467 13.4142 7.58609C13.8047 7.97661 14 8.48846 14 9.00031C14 9.51215 13.8047 10.024 13.4142 10.4145C13.0228 10.8059 12.5095 11.0012 11.9965 11.0003L12 12.0003"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_743_6086">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

SvgHelp.defaultProps = defaultProps;

export default memo(SvgHelp);
