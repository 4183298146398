import { createSlice } from "@reduxjs/toolkit";
import { GetUserReducerState } from "./profile.types";
import { getUserMiddleWare } from "./profileMiddleware";

const getUserInitialState: GetUserReducerState = {
  isLoading: false,
  error: "",
  userDetails: {
    __v: 0,
    _id: "",
    archived: false,
    createdAt: "",
    email: "",
    firstName: "",
    isActive: false,
    lastName: "",
    role: "",
    updatedAt: "",
    userName: "",
    phoneNumber: { extension: "", number: "" },
    gender: "",
  },
};

const getUserReducer = createSlice({
  name: "getUser",
  initialState: getUserInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userDetails = action.payload?.userDetails;
    });
    builder.addCase(getUserMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

export const getUserReducers = getUserReducer.reducer;
