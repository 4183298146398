import { useEffect } from "react";
import { setLoader } from "../../common/store/commonReducer";
import { RootState, useAppDispatch } from "../../redux/store";
import {
  getHistoryPhysicalMiddleWare,
  updateHistoryPhysicalMiddleWare,
} from "./store/historyPhysicalMiddleware";
import { connect } from "react-redux";
import { HealthAndPhysicalExamV1Logs } from "./store/historyPhysicalTypes";
import { routes } from "../../routes/routesPath";
import { useNavigate } from "react-router-dom";
import { Flex, VimeoVideoOnly } from "../../packages";
import { Typography } from "@mui/material";
import HPContainer from "../../common/HPContainer";
import { FONTS } from "../../utils/fontConstant";
import { GREY_TEXT_1 } from "../../theme/colors";

const mapStateToProps = (state: RootState) => {
  const { getHistoryPhysicalReducers } = state;
  return {
    healthAndPhysicalExamV1Logs:
      getHistoryPhysicalReducers.data?.healthAndPhysicalExamV1Logs,
  };
};

type Props = {
  healthAndPhysicalExamV1Logs?: HealthAndPhysicalExamV1Logs;
};

const HistoryPhysical17A = ({ healthAndPhysicalExamV1Logs }: Props) => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(getHistoryPhysicalMiddleWare()).then(() =>
      dispatch(setLoader(false))
    );
  }, []);

  const handleSubmit = () => {
    dispatch(setLoader(true));
    dispatch(
      updateHistoryPhysicalMiddleWare({
        currentPage: "18",
        pageDetails: healthAndPhysicalExamV1Logs?.pageDetails,
      })
    ).finally(() => {
      dispatch(setLoader(false));
      navigation(routes.HISTORY_PHYSICAL_18);
    });
  };

  return (
    <HPContainer title="Gynocomastia" handleSubmit={handleSubmit}>
      <Flex center>
        <VimeoVideoOnly src="https://player.vimeo.com/video/494503928" />
      </Flex>
      <Typography
        sx={{ whiteSpace: "pre-wrap", display: "inline" }}
        color={GREY_TEXT_1}
        fontSize={16}
        fontFamily={FONTS.SOURCESANSPRO_REGULAR}
      >
        <br />
        <br />
        Gynocomastia ("gyno") is a sudden increase in the size of the breast
        gland usually in response to hormone changes. If you have gyno, it is
        important to find a physician who will help you determine the cause and
        prescribe appropriate medications, if necessary. Blind use of aromatase
        inhibitors may be effective at stopping the succession of gyno or
        reversing the size of the gyno, but the drugs could have serious side
        effects. Moreover, if using black or grey market anti-estrogen products,
        you cannot rely on quality control, potency, or even that you are taking
        an authentic product. Aromasin, letrozol, and arimidex are often faked
        as nolvadex due to nolvadex's cheap manufacturing cost. Lastly, gyno has
        a time element to it; the faster it is treated after its onset, the more
        likely it will not be permanent or require surgery to remove - another
        reason to consult an expert physician upon a case of gyno.
      </Typography>
    </HPContainer>
  );
};

export default connect(mapStateToProps)(HistoryPhysical17A);
