import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
  fill: "#50BDB2",
  focused: false,
};

const SvgAbcd = ({ width, height, fill, focused }: typeof defaultProps) => (
  <svg
    opacity={focused ? 1 : 0.5}
    width={width}
    height={height}
    viewBox="0 0 39 40"
    fill="none"
  >
    <path
      d="M3.65625 4.76562V33.4062H37.1719C37.6567 33.4062 38.1217 33.5989 38.4646 33.9417C38.8074 34.2845 39 34.7495 39 35.2344C39 35.7192 38.8074 36.1842 38.4646 36.5271C38.1217 36.8699 37.6567 37.0625 37.1719 37.0625H1.82812C1.34328 37.0625 0.878285 36.8699 0.535445 36.5271C0.192605 36.1842 0 35.7192 0 35.2344L0 4.76562C0 4.28078 0.192605 3.81579 0.535445 3.47295C0.878285 3.13011 1.34328 2.9375 1.82812 2.9375C2.31297 2.9375 2.77796 3.13011 3.1208 3.47295C3.46364 3.81579 3.65625 4.28078 3.65625 4.76562ZM38.4637 10.9325L25.6669 23.7294C25.3241 24.0717 24.8595 24.264 24.375 24.264C23.8905 24.264 23.4259 24.0717 23.0831 23.7294L17.0625 17.7087L10.4325 24.3387C10.0857 24.6615 9.62736 24.8372 9.15371 24.8291C8.68007 24.8209 8.22803 24.6294 7.89262 24.2949C7.55809 23.9595 7.36662 23.5074 7.35843 23.0338C7.35025 22.5601 7.526 22.1018 7.84875 21.755L15.7706 13.8331C16.1134 13.4908 16.578 13.2985 17.0625 13.2985C17.547 13.2985 18.0116 13.4908 18.3544 13.8331L24.375 19.8538L35.88 8.34875C36.2268 8.026 36.6851 7.85025 37.1588 7.85843C37.6324 7.86662 38.0845 8.05809 38.4199 8.39262C38.7544 8.72803 38.9459 9.18007 38.9541 9.65371C38.9622 10.1274 38.7865 10.5857 38.4637 10.9325Z"
      fill={fill}
    />
  </svg>
);

SvgAbcd.defaultProps = defaultProps;

export default memo(SvgAbcd);
