import { Box, Typography } from "@mui/material";
import { SvgUpload } from "../../icons";
import { GREY_TEXT_1, WHITE } from "../../theme/colors";
import { FONTS } from "../../utils/fontConstant";
import styles from "./uploadimage.module.scss";
import { useRef } from "react";
import { isEmpty } from "../../utils/validators";

type Props = {
  title: string;
  description: string;
  onChange: (a: any) => void;
  value?: string;
};
const UploadImage = ({ title, description, onChange, value }: Props) => {
  const fileInputRef = useRef<any>(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };
  return (
    <Box onClick={handleClick} className={styles.overAll} sx={{ gap: 1 }}>
      <Box
        justifyContent="center"
        display="flex"
        flexDirection={"row"}
        alignItems={"center"}
      >
        <Typography
          sx={{ mr: 1 }}
          fontSize={14}
          fontFamily={FONTS.MONTSERRAT_SEMIBOLD}
          color={WHITE}
        >
          {title}
        </Typography>
        <SvgUpload />
      </Box>
      <input
        ref={fileInputRef}
        onChange={(event: any) => onChange(event.target.files[0])}
        accept="image/png,image/jpeg"
        hidden
        type="file"
      />
      <Typography align="center" fontSize={14} color={GREY_TEXT_1}>
        {description}
      </Typography>
      {!isEmpty(value) && (
        <img alt="size" className={styles.imgStyle} src={value} />
      )}
      <Typography align="center" fontSize={14} color={GREY_TEXT_1}>
        Size: 1:8 ratio
      </Typography>
    </Box>
  );
};

export default UploadImage;
