import classNames from "classnames/bind";
import styles from "./flex.module.css";
import { CSSProperties, ReactNode } from "react";

const cx = classNames.bind(styles);
const defaultProps = {
  column: true,
};

type Props = {
  children: ReactNode;
  between?: boolean;
  around?: boolean;
  evenly?: boolean;
  center?: boolean;
  start?: boolean;
  end?: boolean;
  middle?: boolean;
  top?: boolean;
  bottom?: boolean;
  row?: boolean;
  rowReverse?: boolean;
  columnReverse?: boolean;
  column?: boolean;
  flex?: number;
  wrapReverse?: boolean;
  wrap?: boolean;
  className?: string;
  id?: string;
  style?: CSSProperties;
  onClick?: () => void
} & typeof defaultProps;

const Flex = ({
  children,
  className,
  between,
  column,
  around,
  evenly,
  flex,
  row,
  center,
  start,
  end,
  middle,
  top,
  bottom,
  rowReverse,
  columnReverse,
  wrap,
  wrapReverse,
  id,
  style,
  onClick
}: Props) => {
  const flexClassName = cx(
    {
      ["between"]: between,
      ["column"]: column && !row,
      ["around"]: around,
      ["evenly"]: evenly,
      ["center"]: center,
      ["start"]: start,
      ["end"]: end,
      ["middle"]: middle,
      ["top"]: top,
      ["bottom"]: bottom,
      ["rowReverse"]: rowReverse,
      ["columnReverse"]: columnReverse,
      ["wrap"]: wrap,
      ["wrapReverse"]: wrapReverse,
      ["row"]: row,
    },
    className
  );

  return (
    <div id={id} style={{ ...style, flex }} className={flexClassName} onClick={onClick}>
      {children}
    </div>
  );
};

Flex.defaultProps = defaultProps;

export default Flex;
