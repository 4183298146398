import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import LabelWrapper from "../LabelWrapper/LabelWrapper";
import { isEmpty } from "../../utils/validators";
import { GREY_TEXT_1 } from "../../theme/colors";

type Props = {
  label?: string;
  labelColor?: string;
  labelSize?: number;
  error?: boolean;
  onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  value?: string;
  placeholder?: string;
  fullWidth?: boolean;
  options?: any[];
  sx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  labelClassName?: string;
  displayEmpty?: boolean;
  required?: boolean;
  disabled?: boolean;
  labelKey?: string;
  valueKey?: string;
  onClear?: () => void;
};

const SelectTag = ({
  label,
  labelSize,
  labelColor,
  error,
  onChange,
  value,
  placeholder,
  fullWidth,
  options,
  sx,
  labelClassName,
  labelSx,
  displayEmpty = true,
  required,
  disabled,
  labelKey = "label",
  valueKey = "value",
  onClear,
}: Props) => {
  return (
    <LabelWrapper
      required={required}
      labelSx={labelSx}
      labelClassName={labelClassName}
      label={label}
      labelColor={labelColor}
      labelSize={labelSize}
    >
      <Select
        disabled={disabled}
        style={{ borderRadius: 12 }}
        sx={sx}
        error={error}
        fullWidth={fullWidth}
        value={value}
        onChange={onChange}
        displayEmpty={displayEmpty}
        renderValue={
          isEmpty(value)
            ? () => <Typography color={GREY_TEXT_1}>{placeholder}</Typography>
            : undefined
        }
        endAdornment={
          value &&
          onClear && (
            <InputAdornment sx={{ marginRight: "10px" }} position="end">
              <IconButton onClick={onClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      >
        {Array.isArray(options) &&
          options?.length > 0 &&
          options?.map((list) => {
            return (
              <MenuItem key={list[valueKey]} value={list[valueKey]}>
                {list[labelKey]}
              </MenuItem>
            );
          })}
      </Select>
    </LabelWrapper>
  );
};

export default SelectTag;
