import {createSlice} from '@reduxjs/toolkit';
import {GetFileReducerState, GetReportReducerState} from './mylab.types';
import {getFileMiddleWare, getLabReportMiddleWare} from './mylabMiddleware';

const getLabReportInitialState: GetReportReducerState = {
  isLoading: false,
  error: '',
  data: {
    page: 0,
    pageSize: 0,
    totalLabReports: 0,
    sortBy: '',
    sortOrder: 0,
  },
};

const getLabReportReducer = createSlice({
  name: 'get_lab_report',
  initialState: getLabReportInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getLabReportMiddleWare.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getLabReportMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getLabReportMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

const getFileInitialState: GetFileReducerState = {
  isLoading: false,
  error: '',
  file: {
    fileUrl: '',
  },
};

const getFileReducer = createSlice({
  name: 'get_file',
  initialState: getFileInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getFileMiddleWare.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getFileMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.file = action.payload?.file;
    });
    builder.addCase(getFileMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

export const getLabReportReducers = getLabReportReducer.reducer;
export const getFileReducers = getFileReducer.reducer;
