import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 25,
};

const SvgDoc = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 24 25" fill="none">
    <path
      d="M8 3.12891H9C9 2.76011 8.79702 2.42125 8.47186 2.24723C8.1467 2.07321 7.75216 2.09229 7.4453 2.29686L8 3.12891ZM15.6641 4.23831L16.2188 3.40626V3.40626L15.6641 4.23831ZM14 3.12891L14.5547 2.29686C14.2478 2.09229 13.8533 2.07321 13.5281 2.24723C13.203 2.42125 13 2.76011 13 3.12891H14ZM7 4.62891C7 5.18119 7.44772 5.62891 8 5.62891C8.55228 5.62891 9 5.18119 9 4.62891H7ZM13 4.62891C13 5.18119 13.4477 5.62891 14 5.62891C14.5523 5.62891 15 5.18119 15 4.62891H13ZM16 6.73446V10.1289H18V6.73446H16ZM13 13.1289H9V15.1289H13V13.1289ZM6 10.1289V6.73446H4V10.1289H6ZM6.8906 5.07036L8.5547 3.96096L7.4453 2.29686L5.7812 3.40626L6.8906 5.07036ZM16.2188 3.40626L14.5547 2.29686L13.4453 3.96096L15.1094 5.07036L16.2188 3.40626ZM7 3.12891V4.62891H9V3.12891H7ZM13 3.12891V4.62891H15V3.12891H13ZM9 13.1289C7.34315 13.1289 6 11.7858 6 10.1289H4C4 12.8903 6.23858 15.1289 9 15.1289V13.1289ZM16 10.1289C16 11.7858 14.6569 13.1289 13 13.1289V15.1289C15.7614 15.1289 18 12.8903 18 10.1289H16ZM18 6.73446C18 5.39704 17.3316 4.14812 16.2188 3.40626L15.1094 5.07036C15.6658 5.44129 16 6.06575 16 6.73446H18ZM6 6.73446C6 6.06575 6.3342 5.44129 6.8906 5.07036L5.7812 3.40626C4.6684 4.14812 4 5.39704 4 6.73446H6ZM10 14.1289V20.1289H12V14.1289H10ZM12 22.1289H14V20.1289H12V22.1289ZM16 20.1289V18.4925H14V20.1289H16ZM16 18.4925H17V16.4925H16V18.4925ZM16 18.4925V18.4925V16.4925C14.8954 16.4925 14 17.388 14 18.4925H16ZM14 22.1289C15.1046 22.1289 16 21.2335 16 20.1289H14V22.1289ZM10 20.1289C10 21.2335 10.8954 22.1289 12 22.1289V20.1289H10ZM19 17.4921C19 17.7682 18.7761 17.9921 18.5 17.9921V19.9921C19.8807 19.9921 21 18.8728 21 17.4921H19ZM18.5 17.9921C18.2235 17.9921 18 17.7683 18 17.4925H16C16 18.8736 17.1197 19.9921 18.5 19.9921V17.9921ZM18 17.4925C18 17.216 18.2243 16.9921 18.5 16.9921V14.9921C17.1189 14.9921 16 16.1122 16 17.4925H18ZM18.5 16.9921C18.7761 16.9921 19 17.2159 19 17.4921H21C21 16.1114 19.8807 14.9921 18.5 14.9921V16.9921Z"
      fill="white"
    />
  </svg>
);

SvgDoc.defaultProps = defaultProps;

export default memo(SvgDoc);
