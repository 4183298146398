import { memo } from "react";

const defaultProps = {
  width: 25,
  height: 25,
  fill: "#6B6A73",
};

const SvgPhone = ({ width, height, fill }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 25 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5452 7.77175C11.9486 9.22952 11.4948 10.7503 10.5005 11.6877C10.312 11.8655 10.2512 12.1528 10.3993 12.3654C10.7146 12.818 11.0732 13.2483 11.4752 13.6506C11.8767 14.0524 12.3059 14.4108 12.7574 14.726C12.9706 14.8748 13.2588 14.813 13.4363 14.623C14.3721 13.6207 15.8974 13.1619 17.3591 13.5671C19.3294 14.1132 20.4384 16.0221 19.836 17.8307C18.071 23.1307 11.2755 17.8775 9.26351 15.864C7.20294 13.8019 2.03053 7.04544 7.28475 5.29301C9.09201 4.6902 10.9995 5.79998 11.5452 7.77175Z"
      stroke={fill}
      strokeLinecap="round"
    />
  </svg>
);

SvgPhone.defaultProps = defaultProps;

export default memo(SvgPhone);
