import MuiLinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FONTS } from "../../utils/fontConstant";
import { isEmpty } from "../../utils/validators";

export type progressColorType = "success" | "error" | "warning" | "dark_error";

type Props = {
  percentage: number;
  progressColor?: progressColorType;
  label?: string;
};

const LinearProgress = ({ percentage, progressColor, label }: Props) => {
  let colors;

  if (progressColor === "success") {
    colors = `linear-gradient(to right, #0BE83C, #0BCF5E,#0AA794)`;
  } else if (progressColor === "error") {
    colors = `linear-gradient(to right, #E80B2B, #A70A21)`;
  } else if (progressColor === "warning") {
    colors = `linear-gradient(to right, #EFAA5A, #BB3518)`;
  } else {
    colors = `linear-gradient(to right, #B30027, #660016)`;
  }

  return (
    <Box>
      <MuiLinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          width: "100%",
          height: 24,
          borderRadius: 1,
          backgroundColor: "#F5F5FA" + 10,
          "& .MuiLinearProgress-bar": {
            background: colors,
          },
        }}
      />
      {!isEmpty(label) && (
        <Typography
          fontSize={14}
          fontFamily={FONTS.SOURCESANSPRO_REGULAR}
          color={"white"}
          sx={{ mt: 1 }}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};

export default LinearProgress;
