import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
};

const SvgTikTok = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <g
      fill="none"
      stroke="#A5A5A5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      color="#A5A5A5"
    >
      <path d="M2.5 12c0-4.478 0-6.718 1.391-8.109S7.521 2.5 12 2.5c4.478 0 6.718 0 8.109 1.391S21.5 7.521 21.5 12c0 4.478 0 6.718-1.391 8.109S16.479 21.5 12 21.5c-4.478 0-6.718 0-8.109-1.391S2.5 16.479 2.5 12" />
      <path d="M10.536 11.008c-.82-.116-2.69.075-3.606 1.77s.007 3.459.584 4.129c.569.627 2.378 1.814 4.297.655c.476-.287 1.069-.502 1.741-2.747l-.078-8.834c-.13.973.945 3.255 4.004 3.525" />
    </g>
  </svg>
);

SvgTikTok.defaultProps = defaultProps;

export default memo(SvgTikTok);
