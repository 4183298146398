import { FormikProps } from "formik";
import currencySymbolMap from "currency-symbol-map";
import { WHITE } from "../theme/colors";
import { FONTS } from "./fontConstant";
import { isEmpty, NUMERIC_REGEX } from "./validators";
import { PROFILE_PLACEHOLDER } from "./constants";

export const getPasswordStrength = (value: string) => {
  const isLengthValid = value.length >= 8 && value.length <= 12;

  // Validate uppercase
  const containsUppercase = /[A-Z]/.test(value);

  // Validate special character
  const containsSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

  // Validate lowercase
  const containsLowercase = /[a-z]/.test(value);

  return (
    !isLengthValid ||
    !containsSpecialChar ||
    !containsUppercase ||
    !containsLowercase
  );
};

export function isEmptyObject(obj: any) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export function areAllValuesEmpty(obj: any) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === "string" && value.trim() !== "") {
        return false;
      } else if (typeof value === "object") {
        if (!areAllValuesEmpty(value)) {
          return false;
        }
      }
    }
  }
  return true;
}

export function jsonStringToJSON(inputString: string) {
  const parts = inputString.match(
    /(.*?) (.*?) in '(.*?)' \((.*?)\): value=(.*?)$/
  );
  if (!parts || parts.length < 6) {
    return null;
  }
  const timestamp = parts[1];
  const location = parts[3];
  const source = parts[4];
  const value = parseFloat(parts[5]);

  const jsonObject = {
    timestamp,
    location,
    source,
    measurement: {
      type: parts[2],
      value,
    },
  };

  return jsonObject;
}

export const handleInputNumeric = (
  text: string,
  formik: FormikProps<any>,
  name: string
) => {
  if (text === "" || NUMERIC_REGEX.test(text)) {
    formik.setFieldValue(name, text);
  }
};

export const getPicLetters = (value = "") => {
  return value.match(/\b\w/g);
};

export const getRandomColor = () => {
  const getRandomInt = (min: any, max: any) =>
    Math.floor(Math.random() * (max - min + 1)) + min;
  let r, g, b;
  do {
    r = getRandomInt(0, 255);
    g = getRandomInt(0, 255);
    b = getRandomInt(0, 255);
  } while (r === 255 && g === 255 && b === 255);
  const hex = ((r << 16) | (g << 8) | b).toString(16);
  const color = "#" + ("000000" + hex).slice(-6);

  return color;
};

export function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: getRandomColor(),
      color: WHITE,
      fontFamily: FONTS.MONTSERRAT_BOLD,
      fontSize: 24,
      width: 100,
      height: 100,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const scrollToSection = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

export const scrollToSectionRef = (ref: any) => {
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const validateFileSize = (size: number) => {
  // Get file size in bytes
  const fileSize = size;

  // Convert bytes to megabytes
  const fileSizeInMB = fileSize / (1024 * 1024);

  // Set the maximum allowed size (5 MB)
  const maxAllowedSize = 5;

  // Check if file size exceeds limit
  if (fileSizeInMB > maxAllowedSize) {
    alert("File size exceeds 5MB limit!");
    return false; // File size is too large
  }

  return true; // File size is valid
};

export const isAdminRole = (userPlan: string) => {
  return (
    userPlan === "admin" ||
    userPlan === "exodus-admin" ||
    userPlan === "influencer"
  );
};

export const isUserRole = (userPlan: string) => {
  return userPlan === "user";
};
export const isTestosteronologistRole = (userPlan: string) => {
  return userPlan === "testosteronologist";
};

export const isFree = (userPlan: string) => {
  return userPlan === "free";
};

export const isPaid = (userPlan?: string) => {
  return userPlan === "paid";
};

export const isMale = (gender?: string) => {
  return gender === "male";
};

export const handleBlockPopUp = (
  url: string | any,
  successCallBack: () => void,
  errorCallBack: () => void
) => {
  const newWindow = window.open(url, "_blank");
  if (
    !newWindow ||
    newWindow.closed ||
    typeof newWindow.closed === "undefined"
  ) {
    errorCallBack();
    alert(
      "Popup was blocked by the browser. Please disable pop-up blockers to proceed, or the action may occur in this window."
    );
  } else {
    // Focus the new window if it was not blocked
    newWindow.focus();
    successCallBack();
  }
};

export const camelCaseString = (snakeCaseString: string) => {
  return snakeCaseString
    .toLowerCase()
    .replace(/(_\w)/g, (matches) => matches[1].toUpperCase())
    .replace(/^./, (match) => match.toUpperCase());
};

export const shuffleArray = (array?: any[]) => {
  if (array) {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  } else {
    return [];
  }
};

export const currencyConvertedSymbol = (currencyCode: string) => {
  const convertedSymbol =
    currencySymbolMap(currencyCode?.toUpperCase()) || "Unknown Currency";
  return convertedSymbol;
};

export const profileCheck = (profile: string) => {
  return isEmpty(profile) ? PROFILE_PLACEHOLDER : profile;
};
