import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
  fill: "#fff",
  focused: false,
};

const SvgDoctor1 = ({ width, height, fill, focused }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <g opacity={focused ? 1 : "0.5"}>
      <path
        d="M8.57143 3V2C8.01914 2 7.57143 2.44772 7.57143 3H8.57143ZM15.4286 3H16.4286C16.4286 2.44772 15.9809 2 15.4286 2V3ZM13 15V14H11V15H13ZM11 18V19H13V18H11ZM13.5 17.5H14.5V15.5H13.5V17.5ZM10.5 15.5H9.5V17.5H10.5V15.5ZM14.1704 11.187L13.5185 10.4287L14.1704 11.187ZM17 16.05V19.2H19V16.05H17ZM16.2857 20H7.71429V22H16.2857V20ZM7 19.2V16.05H5V19.2H7ZM7.71429 20C7.36521 20 7 19.6884 7 19.2H5C5 20.6999 6.16981 22 7.71429 22V20ZM17 19.2C17 19.6884 16.6348 20 16.2857 20V22C17.8302 22 19 20.6999 19 19.2H17ZM8.57143 4H15.4286V2H8.57143V4ZM14.4286 3V6.6H16.4286V3H14.4286ZM15.4286 5.6H8.57143V7.6H15.4286V5.6ZM9.57143 6.6V3H7.57143V6.6H9.57143ZM11 15V16.5H13V15H11ZM11 16.5V18H13V16.5H11ZM13.5 15.5H12V17.5H13.5V15.5ZM12 15.5H10.5V17.5H12V15.5ZM12 11C11.4296 11 10.902 10.7902 10.4815 10.4287L9.17777 11.9454C9.93946 12.6001 10.9234 13 12 13V11ZM10.4815 10.4287C9.93244 9.95672 9.57143 9.22944 9.57143 8.4H7.57143C7.57143 9.81759 8.19074 11.0969 9.17777 11.9454L10.4815 10.4287ZM9.63413 10.2063C6.96189 10.739 5 13.1814 5 16.05H7C7 14.0859 8.33586 12.5045 10.0251 12.1677L9.63413 10.2063ZM14.4286 8.4C14.4286 9.22944 14.0676 9.95672 13.5185 10.4287L14.8222 11.9454C15.8093 11.0969 16.4286 9.81759 16.4286 8.4H14.4286ZM13.5185 10.4287C13.098 10.7902 12.5704 11 12 11V13C13.0766 13 14.0605 12.6001 14.8222 11.9454L13.5185 10.4287ZM13.9749 12.1677C15.6641 12.5045 17 14.0859 17 16.05H19C19 13.1814 17.0381 10.739 14.3659 10.2063L13.9749 12.1677ZM9.57143 8.4V6.6H7.57143V8.4H9.57143ZM14.4286 6.6V8.4H16.4286V6.6H14.4286Z"
        fill={fill}
      />
    </g>
  </svg>
);

SvgDoctor1.defaultProps = defaultProps;

export default memo(SvgDoctor1);
