import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getTestoConnectApi,
  getUserConnectApi,
  requestTestoConnectApi,
  requestUserConnectApi,
} from "../../../routes/apiRoutes";
import {
  GET_TESTO_CONNECT,
  GET_USER_CONNECT,
  REQUEST_TESTO_CONNECT,
  REQUEST_USER_CONNECT,
} from "../../../redux/actions";

export const getTestoConnectMiddleWare = createAsyncThunk(
  GET_TESTO_CONNECT,
  async ({ status }: { status: "pending" | "accepted" | "others" }) => {
    try {
      const { data } = await axios.get(getTestoConnectApi(status));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const requestTestoConnectTestoMiddleWare = createAsyncThunk(
  REQUEST_TESTO_CONNECT,
  async ({
    testoId,
    status,
  }: {
    testoId: string;
    status: "pending" | "revoked_by_user";
  }) => {
    try {
      const { data } = await axios.post(
        requestTestoConnectApi(testoId, status)
      );
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getUserConnectMiddleWare = createAsyncThunk(
  GET_USER_CONNECT,
  async ({ status }: { status: "pending" | "accepted" }) => {
    try {
      const { data } = await axios.get(getUserConnectApi(status));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const requestUserConnectMiddleWare = createAsyncThunk(
  REQUEST_USER_CONNECT,
  async ({
    userId,
    status,
  }: {
    userId: string;
    status: "accepted" | "rejected_by_testo" | "revoked_by_testo";
  }) => {
    try {
      const { data } = await axios.post(requestUserConnectApi(userId, status));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
