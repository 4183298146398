import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
  focused: false,
};

const SvgDoctor = ({ width, height, focused }: typeof defaultProps) =>
  focused ? (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M23.5 8L20.5 11.5V13.5L23.5 16.5V8Z"
        // fill="url(#paint0_linear_569_1025)"
        fill="#35D7F3"
      />
      <path
        d="M1 7C1 4.79086 2.89035 3 5.22222 3H15.7778C18.1096 3 20 4.79086 20 7V17C20 19.2091 18.1096 21 15.7778 21H5.22222C2.89035 21 1 19.2091 1 17V7Z"
        // fill="url(#paint1_linear_569_1025)"
        fill="#35D7F3"
      />
      <path
        opacity="0.5"
        d="M10.5 8V15"
        stroke="#090615"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        opacity="0.5"
        d="M7 11.5L14 11.5"
        stroke="#090615"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_569_1025"
          x1="23.5"
          y1="8"
          x2="20.3789"
          y2="8.14716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35D7F3" />
          <stop offset="1" stopColor="#116B7A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_569_1025"
          x1="20"
          y1="3"
          x2="0.575363"
          y2="5.73902"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35D7F3" />
          <stop offset="1" stopColor="#116B7A" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none">
      <g opacity="0.5">
        <path
          d="M1 7C1 4.79086 2.89035 3 5.22222 3H15.7778C18.1096 3 20 4.79086 20 7V17C20 19.2091 18.1096 21 15.7778 21H5.22222C2.89035 21 1 19.2091 1 17V7Z"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M23.5 8L20.5 11.5V13.5L23.5 16.5V8Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 8V15"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 11.5L14 11.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );

SvgDoctor.defaultProps = defaultProps;

export default memo(SvgDoctor);
