import { memo } from "react";

const defaultProps = {
  width: 58,
  height: 58,
};

const SvgWorld1 = ({ width, height }: typeof defaultProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.9997 53.1666C25.6566 53.1666 22.515 52.5318 19.5747 51.2623C16.6344 49.9927 14.0768 48.2712 11.9018 46.0978C9.72676 43.9228 8.00529 41.3652 6.73734 38.4249C5.4694 35.4846 4.83462 32.343 4.83301 28.9999C4.83301 25.6569 5.46779 22.5152 6.73734 19.5749C8.0069 16.6346 9.72837 14.077 11.9018 11.902C14.0768 9.727 16.6344 8.00553 19.5747 6.73759C22.515 5.46964 25.6566 4.83486 28.9997 4.83325C32.3427 4.83325 35.4844 5.46803 38.4247 6.73759C41.365 8.00714 43.9226 9.72861 46.0976 11.902C48.2726 14.077 49.9949 16.6346 51.2644 19.5749C52.534 22.5152 53.168 25.6569 53.1663 28.9999C53.1663 32.343 52.5316 35.4846 51.262 38.4249C49.9925 41.3652 48.271 43.9228 46.0976 46.0978C43.9226 48.2728 41.365 49.9951 38.4247 51.2647C35.4844 52.5342 32.3427 53.1682 28.9997 53.1666ZM28.9997 48.3333C34.3969 48.3333 38.9684 46.4603 42.7143 42.7145C46.4601 38.9687 48.333 34.3971 48.333 28.9999C48.333 28.718 48.3233 28.4256 48.304 28.1227C48.2847 27.8198 48.2742 27.5684 48.2726 27.3687C48.0712 28.5367 47.5275 29.5034 46.6413 30.2687C45.7552 31.0339 44.708 31.4166 43.4997 31.4166H38.6663C37.3372 31.4166 36.1997 30.9437 35.254 29.998C34.3083 29.0523 33.8346 27.914 33.833 26.5833V24.1666H24.1663V19.3333C24.1663 18.0041 24.64 16.8666 25.5873 15.9209C26.5347 14.9752 27.6721 14.5015 28.9997 14.4999H31.4163C31.4163 13.5735 31.6685 12.7583 32.1728 12.0543C32.677 11.3502 33.2909 10.7758 34.0143 10.3312C33.2087 10.1298 32.3935 9.96867 31.5686 9.84784C30.7437 9.727 29.8874 9.66659 28.9997 9.66659C23.6025 9.66659 19.0309 11.5395 15.2851 15.2853C11.5393 19.0312 9.66634 23.6027 9.66634 28.9999H21.7497C24.408 28.9999 26.6837 29.9464 28.5768 31.8395C30.4698 33.7326 31.4163 36.0083 31.4163 38.6666V41.0833H24.1663V47.7291C24.9719 47.9305 25.7678 48.0819 26.554 48.1834C27.3402 48.2849 28.1555 48.3349 28.9997 48.3333Z"
      fill="white"
    />
  </svg>
);

SvgWorld1.defaultProps = defaultProps;

export default memo(SvgWorld1);
