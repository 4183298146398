import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
  focused: false,
};

const SvgHome = ({ width, height, focused }: typeof defaultProps) =>
  focused ? (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M3 10.8074C3 9.90934 3.40231 9.05854 4.09639 8.4887L9.46186 4.08372C10.9372 2.87248 13.0628 2.87248 14.5381 4.08372L19.9036 8.4887C20.5977 9.05854 21 9.90934 21 10.8074V17.9999C21 19.6568 19.6569 20.9999 18 20.9999H6C4.34315 20.9999 3 19.6568 3 17.9999V10.8074Z"
        // fill="url(#paint0_linear_569_987)"
        fill={"#35D7F3"}
      />
      <path
        opacity="0.5"
        d="M15 21V17C15 15.3431 13.6569 14 12 14C10.3431 14 9 15.3431 9 17V21H15Z"
        fill="#090615"
      />
      <defs>
        <linearGradient
          id="paint0_linear_569_987"
          x1="21"
          y1="3.1753"
          x2="2.56726"
          y2="5.66188"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35D7F3" />
          <stop offset="1" stopColor="#116B7A" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g opacity="0.5">
        <path
          d="M3 10.8074C3 9.90934 3.40231 9.05854 4.09639 8.4887L9.46186 4.08372C10.9372 2.87248 13.0628 2.87248 14.5381 4.08372L19.9036 8.4887C20.5977 9.05854 21 9.90934 21 10.8074V17.9999C21 19.6568 19.6569 20.9999 18 20.9999H6C4.34315 20.9999 3 19.6568 3 17.9999V10.8074Z"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M15 21V17C15 15.3431 13.6569 14 12 14C10.3431 14 9 15.3431 9 17V21"
          stroke="white"
          strokeWidth="2"
        />
      </g>
    </svg>
  );

SvgHome.defaultProps = defaultProps;

export default memo(SvgHome);
