import { createSlice } from "@reduxjs/toolkit";
import { subscribeMiddleWare } from "./signupMiddleware";
import { SubscribeReducerState } from "./signup.types";

const subscribeInitialState: SubscribeReducerState = {
  isLoading: false,
  error: "",
  data: {
    stripeCheckoutSessionURL: "",
    paymentId: "",
  },
};

const subscribeReducer = createSlice({
  name: "subscribe",
  initialState: subscribeInitialState,
  reducers: {
    setSubscribe: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(subscribeMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(subscribeMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(subscribeMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

export const { setSubscribe } = subscribeReducer.actions;

export const subscribeReducers = subscribeReducer.reducer;
