import { createSlice } from "@reduxjs/toolkit";
import { GetTodoListReducerState, ToDoItemReducerState } from "./todo.types";
import {
  getTodoItemsMiddleWare,
  getTodoListMiddleWare,
} from "./todoMiddleware";

const toDoItemInitialState: ToDoItemReducerState = {
  isLoading: false,
  error: "",
  todos: [],
};

const getTodoItemReducer = createSlice({
  name: "get_todo_items",
  initialState: toDoItemInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTodoItemsMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTodoItemsMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.todos = action.payload?.todos;
    });
    builder.addCase(getTodoItemsMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

const getTodoListInitialState: GetTodoListReducerState = {
  isLoading: false,
  error: "",
  data: {
    totaltodos: 0,
    page: 0,
    pageSize: 0,
    sortBy: "",
    sortOrder: 0,
    todos: [],
  },
};

const getTodoListReducer = createSlice({
  name: "get_todo_list",
  initialState: getTodoListInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTodoListMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTodoListMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getTodoListMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

export const getTodoItemReducers = getTodoItemReducer.reducer;
export const getTodoListReducers = getTodoListReducer.reducer;
