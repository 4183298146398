import { createSlice } from "@reduxjs/toolkit";
import {
  GetTestoConnectReducerState,
  GetUserConnectReducerState,
} from "./connectTesto.types";
import {
  getTestoConnectMiddleWare,
  getUserConnectMiddleWare,
} from "./connectTestoMiddleware";

const getTestoInitialState: GetTestoConnectReducerState = {
  isLoading: false,
  error: "",
  data: {
    totalTestosteronologists: 0,
    page: 0,
    pageSize: 0,
  },
};

const getTestoConnectReducer = createSlice({
  name: "get-testo-connect",
  initialState: getTestoInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTestoConnectMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTestoConnectMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getTestoConnectMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

const getUserInitialState: GetUserConnectReducerState = {
  isLoading: false,
  error: "",
  data: {
    message: "",
    page: 0,
    pageSize: 0,
  },
};

const getUserConnectReducer = createSlice({
  name: "get-user-connect",
  initialState: getUserInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserConnectMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserConnectMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getUserConnectMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

export const getTestoConnectReducers = getTestoConnectReducer.reducer;
export const getUserConnectReducers = getUserConnectReducer.reducer;
