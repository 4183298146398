import { createSlice } from "@reduxjs/toolkit";
import { getVimeoMiddleWare } from "./videoMiddleware";
import { VimeoReducerState } from "./video.types";

const getVimeoInitialState: VimeoReducerState = {
  isLoading: false,
  error: "",
  videos: null,
  totalVideos: 0,
  page: 0,
  pageSize: 0,
  sortBy: "",
  sortOrder: 0,
};

const getVimeoReducer = createSlice({
  name: "get_vimeo",
  initialState: getVimeoInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVimeoMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVimeoMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.videos = action.payload?.videos;
      state.totalVideos = action.payload?.totalVideos;
    });
    builder.addCase(getVimeoMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

export const getVimeoReducers = getVimeoReducer.reducer;
