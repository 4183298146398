import {
  Checkbox as CheckboxMUI,
  FormControlLabel,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { FONTS } from "../../utils/fontConstant";
import { ReactNode } from "react";

type Props = {
  label?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  checked?: boolean;
  sx?: SxProps<Theme> | undefined;
};
const CheckBox = ({ label, onClick, checked, sx }: Props) => {
  return (
    <FormControlLabel
      sx={sx}
      control={<CheckboxMUI onClick={onClick} checked={checked} />}
      label={
        typeof label === "string" ? (
          label
        ) : (
          <Typography
            fontSize={14}
            fontFamily={FONTS.MONTSERRAT_SEMIBOLD}
            color="white"
          >
            {label}
          </Typography>
        )
      }
    />
  );
};

export default CheckBox;
