import { memo } from "react";

const defaultProps = {
  width: 18,
  height: 18,
};

const SvgInsta = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 0H4.5C2.02522 0 0 2.02432 0 4.5V13.5C0 15.9748 2.02522 18 4.5 18H13.5C15.9748 18 18 15.9748 18 13.5V4.5C18 2.02432 15.9748 0 13.5 0ZM9 12.7499C6.92865 12.7499 5.24993 11.0704 5.24993 9C5.24993 6.92865 6.92865 5.24993 9 5.24993C11.0704 5.24993 12.7501 6.92865 12.7501 9C12.7501 11.0704 11.0704 12.7499 9 12.7499ZM12.7501 4.12492C12.7501 4.74615 13.2532 5.24992 13.8751 5.24992C14.497 5.24992 15.0001 4.74615 15.0001 4.12492C15.0001 3.5037 14.497 2.99992 13.8751 2.99992C13.2532 2.99992 12.7501 3.5037 12.7501 4.12492Z"
      fill="#A5A5A5"
    />
  </svg>
);

SvgInsta.defaultProps = defaultProps;

export default memo(SvgInsta);
