import { memo } from "react";

const defaultProps = {
  width: 49,
  height: 47,
};

const SvgReview = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 49 47" fill="none">
    <path
      d="M42.042 0L46.844 6.19456C45.0147 7.89888 43.2507 10.5209 41.552 14.0607C39.9187 17.5349 39.0367 21.9268 38.906 27.2364L49 28.5146V47H29.4V38.249C29.4 37.4623 29.4327 35.8236 29.498 33.3326C29.5633 30.7761 29.6287 29.0718 29.694 28.2197C29.7593 27.302 29.8573 25.7615 29.988 23.5983C30.1187 21.4351 30.3147 19.8619 30.576 18.8787C30.8373 17.8954 31.164 16.5188 31.556 14.749C31.948 12.9791 32.438 11.5697 33.026 10.5209C33.6793 9.47211 34.398 8.25941 35.182 6.88285C36.0313 5.50628 37.0113 4.29358 38.122 3.24477C39.298 2.1304 40.6047 1.04881 42.042 0ZM13.23 0L18.032 6.19456C16.2027 7.89888 14.4387 10.5209 12.74 14.0607C11.1067 17.5349 10.2247 21.894 10.094 27.1381L19.6 28.5146V47H0V38.249C0 34.2503 0.0326667 31.2022 0.098 29.1046C0.163333 27.007 0.424667 24.3194 0.882 21.0418C1.33933 17.6987 1.99267 14.9784 2.842 12.8808C3.75667 10.7831 5.06333 8.55439 6.762 6.19456C8.526 3.83473 10.682 1.76987 13.23 0Z"
      fill="#FFB51C"
    />
  </svg>
);

SvgReview.defaultProps = defaultProps;

export default memo(SvgReview);
