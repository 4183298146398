import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
  focused: false,
};

const SvgVideo = ({ width, height, focused }: typeof defaultProps) =>
  focused ? (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M1 7C1 4.79086 3.18883 3 5.88889 3H18.1111C20.8112 3 23 4.79086 23 7V17C23 19.2091 20.8112 21 18.1111 21H5.88889C3.18883 21 1 19.2091 1 17V7Z"
        // fill="url(#paint0_linear_569_1020)"
        fill="#35D7F3"
      />
      <path
        opacity="0.5"
        d="M14.5 11.1341C15.1667 11.519 15.1667 12.4813 14.5 12.8662L11.5 14.5982C10.8333 14.9831 10 14.502 10 13.7322L10 10.2681C10 9.49829 10.8333 9.01717 11.5 9.40207L14.5 11.1341Z"
        fill="#090615"
      />
      <defs>
        <linearGradient
          id="paint0_linear_569_1020"
          x1="23"
          y1="3"
          x2="0.65673"
          y2="6.64803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35D7F3" />
          <stop offset="1" stopColor="#116B7A" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g opacity="0.5">
        <path
          d="M1 7C1 4.79086 3.18883 3 5.88889 3H18.1111C20.8112 3 23 4.79086 23 7V17C23 19.2091 20.8112 21 18.1111 21H5.88889C3.18883 21 1 19.2091 1 17V7Z"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M14.5 11.1341C15.1667 11.519 15.1667 12.4813 14.5 12.8662L11.5 14.5982C10.8333 14.9831 10 14.502 10 13.7322L10 10.2681C10 9.49829 10.8333 9.01717 11.5 9.40207L14.5 11.1341Z"
          stroke="white"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );

SvgVideo.defaultProps = defaultProps;

export default memo(SvgVideo);
