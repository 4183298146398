import { createSlice } from "@reduxjs/toolkit";
import { LoaderReducerSate } from "./common.types";

const loaderInitialState: LoaderReducerSate = {
  isLoader: false,
};

export const loaderReducer = createSlice({
  name: "loader",
  initialState: loaderInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.isLoader = action.payload;
    },
  },
});

const profileUrlInitialState = {
  profileUrl: "",
};

export const profileUrlReducer = createSlice({
  name: "profile_url",
  initialState: profileUrlInitialState,
  reducers: {
    setProfileUrl: (state, action) => {
      state.profileUrl = action.payload;
    },
  },
});

export const { setProfileUrl } = profileUrlReducer.actions;
export const { setLoader } = loaderReducer.actions;

export const loaderReducers = loaderReducer.reducer;
export const profileUrlReducers = profileUrlReducer.reducer;
