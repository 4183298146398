import { useEffect, useRef, useCallback, memo } from "react";

// imageUrls = [
//   "https://anabolic-doc-public.s3.amazonaws.com/app-images/Ellipse-2786.png",
//   "https://anabolic-doc-public.s3.amazonaws.com/app-images/Ellipse-2785.png",
//   "https://anabolic-doc-public.s3.amazonaws.com/app-images/Ellipse-2794.png",
// ],
const ParticleEmitter = ({
  imageUrls = [
    require("../../images/ellipse_2786.png"),
    require("../../images/ellipse_2785.png"),
    require("../../images/ellipse_2794.png"),
  ],
}: {
  imageUrls?: string[];
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const animateParticle = useCallback((particle: HTMLDivElement) => {
    const startX = Math.random() * window.innerWidth;
    const startY = Math.random() * window.innerHeight;
    const endX = Math.random() * window.innerWidth;
    const endY = Math.random() * window.innerHeight;
    const duration = 3000 + Math.random() * 3000;

    particle.animate(
      [
        { transform: `translate(${startX}px, ${startY}px)` },
        { transform: `translate(${endX}px, ${endY}px)` },
      ],
      {
        duration: duration,
        iterations: Infinity,
        easing: "linear",
      }
    );
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    const numParticles = 7;

    if (container) {
      for (let i = 0; i < numParticles; i++) {
        const particle = document.createElement("div");
        particle.className = "particle";
        particle.style.backgroundImage = `url(${
          imageUrls[Math.floor(Math.random() * imageUrls.length)]
        })`;
        particle.style.width = `${200}px`;
        particle.style.height = `${200}px`;
        container.appendChild(particle);
        animateParticle(particle);
      }
    }

    return () => {
      if (container) {
        container.innerHTML = "";
      }
    };
  }, [animateParticle, imageUrls]);

  return (
    <div
      style={{ zIndex: 9999999 }}
      ref={containerRef}
      className="particle-container"
    />
  );
};

export default memo(ParticleEmitter);
