import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
  fill: "#50BDB2",
  focused: false,
};

const SvgLegal = ({ width, height, fill, focused }: typeof defaultProps) => (
  <svg
    opacity={focused ? 1 : 0.5}
    width={width}
    height={height}
    viewBox="0 0 800 800"
    fill="none"
  >
    <path
      d="M648.276 800H151.723C136.487 800 124.137 787.65 124.137 772.414V110.345L234.483 0H648.276C663.512 0 675.862 12.35 675.862 27.5859V772.414C675.862 787.65 663.512 800 648.276 800Z"
      fill={fill}
    />
    <path
      d="M234.483 0L124.137 110.345H206.897C222.133 110.345 234.483 97.9953 234.483 82.7594V0Z"
      fill="#C7CFE2"
    />
    <path
      d="M537.931 731.034C507.509 731.034 482.759 706.289 482.759 675.862C482.759 645.436 507.509 620.691 537.931 620.691C568.353 620.691 593.103 645.436 593.103 675.862C593.103 706.289 568.353 731.034 537.931 731.034ZM537.931 648.277C522.717 648.277 510.345 660.656 510.345 675.862C510.345 691.069 522.717 703.448 537.931 703.448C553.145 703.448 565.517 691.069 565.517 675.862C565.517 660.656 553.145 648.277 537.931 648.277Z"
      fill="#5B5D6E"
    />
    <path
      d="M579.311 220.689H220.689C213.072 220.689 206.895 214.52 206.895 206.895C206.895 199.272 213.072 193.102 220.689 193.102H579.309C586.927 193.102 593.103 199.27 593.103 206.895C593.103 214.52 586.928 220.689 579.311 220.689Z"
      fill="#959CB3"
    />
    <path
      d="M579.311 165.517H220.689C213.072 165.517 206.895 159.348 206.895 151.723C206.895 144.1 213.072 137.93 220.689 137.93H579.309C586.927 137.93 593.103 144.098 593.103 151.723C593.103 159.348 586.928 165.517 579.311 165.517Z"
      fill="#959CB3"
    />
    <path
      d="M579.311 275.863H220.689C213.072 275.863 206.895 269.694 206.895 262.069C206.895 254.444 213.072 248.275 220.689 248.275H579.309C586.927 248.275 593.103 254.444 593.103 262.069C593.103 269.694 586.928 275.863 579.311 275.863Z"
      fill="#959CB3"
    />
    <path
      d="M579.311 331.034H220.689C213.072 331.034 206.895 324.866 206.895 317.241C206.895 309.616 213.072 303.447 220.689 303.447H579.309C586.927 303.447 593.103 309.616 593.103 317.241C593.103 324.866 586.928 331.034 579.311 331.034Z"
      fill="#959CB3"
    />
    <path
      d="M579.311 386.206H220.689C213.072 386.206 206.895 380.038 206.895 372.413C206.895 364.789 213.072 358.619 220.689 358.619H579.309C586.927 358.619 593.103 364.788 593.103 372.413C593.103 380.038 586.928 386.206 579.311 386.206Z"
      fill="#959CB3"
    />
    <path
      d="M579.311 441.38H220.689C213.072 441.38 206.895 435.211 206.895 427.586C206.895 419.962 213.072 413.792 220.689 413.792H579.309C586.927 413.792 593.103 419.961 593.103 427.586C593.103 435.211 586.928 441.38 579.311 441.38Z"
      fill="#959CB3"
    />
    <path
      d="M579.311 496.552H220.689C213.072 496.552 206.895 490.383 206.895 482.758C206.895 475.133 213.072 468.964 220.689 468.964H579.309C586.927 468.964 593.103 475.133 593.103 482.758C593.103 490.383 586.928 496.552 579.311 496.552Z"
      fill="#959CB3"
    />
    <path
      d="M579.311 551.723H220.689C213.072 551.723 206.895 545.555 206.895 537.93C206.895 530.306 213.072 524.136 220.689 524.136H579.309C586.927 524.136 593.103 530.305 593.103 537.93C593.103 545.555 586.928 551.723 579.311 551.723Z"
      fill="#959CB3"
    />
    <path
      d="M441.38 662.069H303.448C295.831 662.069 289.655 655.9 289.655 648.275C289.655 640.652 295.831 634.481 303.448 634.481H441.38C448.997 634.481 455.173 640.65 455.173 648.275C455.172 655.9 448.997 662.069 441.38 662.069Z"
      fill="#707487"
    />
    <path
      d="M441.38 717.241H303.448C295.831 717.241 289.655 711.072 289.655 703.447C289.655 695.823 295.831 689.653 303.448 689.653H441.38C448.997 689.653 455.173 695.822 455.173 703.447C455.172 711.072 448.997 717.241 441.38 717.241Z"
      fill="#707487"
    />
  </svg>
);

SvgLegal.defaultProps = defaultProps;

export default memo(SvgLegal);
