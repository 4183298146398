import { memo } from "react";

const defaultProps = {
  width: 321,
  height: 219,
};

const SvgNoResult = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 321 219" fill="none">
    <path
      d="M174.857 52.6699H85.2976C81.4031 52.6699 78.2461 55.8487 78.2461 59.7698V176.254C78.2461 180.175 81.4031 183.353 85.2976 183.353H174.857C178.751 183.353 181.908 180.175 181.908 176.254V59.7698C181.908 55.8487 178.751 52.6699 174.857 52.6699Z"
      fill="#A3CDEC"
    />
    <path
      d="M206.33 31.2656H115.797C111.86 31.2656 108.669 34.4444 108.669 38.3655V154.849C108.669 158.77 111.86 161.949 115.797 161.949H206.33C210.266 161.949 213.458 158.77 213.458 154.849V38.3655C213.458 34.4444 210.266 31.2656 206.33 31.2656Z"
      fill="#1CADC6"
    />
    <path
      d="M167.535 84.8084C167.535 81.3196 165.386 78.5251 161.131 78.5251C158.202 78.5251 155.561 79.9395 153.5 82.2368L150.359 79.4423C153.149 76.3821 156.938 74.0762 161.754 74.0762C168.474 74.0762 172.895 78.0622 172.895 84.4398C172.895 92.969 162.184 96.3978 163.272 105.467H158.43C157.017 95.3863 167.544 91.666 167.544 84.8084H167.535ZM157.026 115.093C157.026 112.642 158.807 110.97 160.982 110.97C163.158 110.97 164.938 112.642 164.938 115.093C164.938 117.545 163.158 119.139 160.982 119.139C158.807 119.139 157.026 117.468 157.026 115.093Z"
      fill="#C4C4C6"
    />
    <path
      d="M239.855 184.444C236.112 187.875 230.319 187.39 227.172 183.395L187.542 133.06L195.349 125.898L240.175 171.461C243.737 175.078 243.59 181.013 239.847 184.435L239.855 184.444Z"
      fill="#494956"
    />
    <path
      d="M182.129 139.572L201.747 121.962C204.724 125.272 204.451 130.371 201.138 133.345L193.529 140.175C190.216 143.149 185.112 142.876 182.135 139.566L182.129 139.572Z"
      fill="#096CB6"
    />
    <path
      opacity="0.3"
      d="M161.627 137.165C183.407 137.165 201.064 119.259 201.064 97.1714C201.064 75.0835 183.407 57.1777 161.627 57.1777C139.847 57.1777 122.19 75.0835 122.19 97.1714C122.19 119.259 139.847 137.165 161.627 137.165Z"
      fill="white"
    />
    <path
      d="M129.243 60.724C109.341 78.8098 107.704 109.793 125.585 129.922C143.465 150.052 174.097 151.717 194.007 133.622C213.908 115.536 215.554 84.5535 197.665 64.4151C179.784 44.2856 149.153 42.6294 129.243 60.7152V60.724ZM187.414 126.205C171.562 140.608 147.167 139.287 132.927 123.253C118.687 107.22 119.993 82.545 135.844 68.1415C151.696 53.7381 176.091 55.0595 190.331 71.0839C204.572 87.1171 203.265 111.792 187.414 126.196V126.205Z"
      fill="white"
    />
    <path
      opacity="0.6"
      d="M193.378 91.9532C193.291 91.9888 193.205 92.0243 193.118 92.051C192.268 92.2733 191.41 91.7487 191.193 90.8863C189.632 84.6805 186.684 79.3104 182.427 74.9006C178.352 70.6863 173.323 68.0902 167.478 67.1655C166.611 67.0322 166.022 66.2053 166.152 65.3162C166.282 64.4271 167.088 63.8226 167.955 63.9559C174.484 64.9784 180.112 67.8946 184.673 72.6067C189.329 77.4256 192.554 83.2935 194.254 90.0683C194.445 90.8418 194.063 91.6331 193.378 91.9532Z"
      fill="url(#paint0_linear_1739_3998)"
    />
    <path
      d="M272.046 56.9365C271.269 55.0742 269.792 53.5821 267.947 52.7974C267.407 52.5671 267.407 51.8165 267.947 51.5862C269.792 50.801 271.269 49.3094 272.046 47.4466C272.274 46.9006 273.017 46.9006 273.245 47.4466C274.022 49.3094 275.5 50.801 277.344 51.5862C277.884 51.8165 277.884 52.5671 277.344 52.7974C275.5 53.5821 274.022 55.0742 273.245 56.9365C273.017 57.4824 272.274 57.4824 272.046 56.9365Z"
      fill="#3849D1"
    />
    <path
      d="M30.0754 63.0289C28.8605 60.1178 26.5514 57.7863 23.669 56.5593C22.8239 56.2001 22.8239 55.0257 23.669 54.6665C26.5514 53.4395 28.8605 51.108 30.0754 48.1964C30.4316 47.3434 31.5934 47.3434 31.9496 48.1964C33.1646 51.108 35.4731 53.4395 38.3555 54.6665C39.2001 55.0257 39.2001 56.2001 38.3555 56.5593C35.4731 57.7863 33.1646 60.1178 31.9496 63.0289C31.5934 63.8819 30.4316 63.8819 30.0754 63.0289Z"
      fill="#00AA6D"
    />
    <path
      opacity="0.3"
      d="M45.0186 115.641C44.2724 113.852 42.8539 112.42 41.0828 111.665C40.5636 111.444 40.5636 110.723 41.0828 110.503C42.8539 109.749 44.2724 108.316 45.0186 106.528C45.2374 106.004 45.9518 106.004 46.17 106.528C46.9168 108.316 48.3354 109.749 50.1065 110.503C50.6251 110.723 50.6251 111.444 50.1065 111.665C48.3354 112.42 46.9168 113.852 46.17 115.641C45.9518 116.165 45.2374 116.165 45.0186 115.641Z"
      fill="#9BD81A"
    />
    <path
      d="M30.6119 158.909C30.0729 157.618 29.0491 156.584 27.7701 156.04C27.3957 155.88 27.3957 155.36 27.7701 155.2C29.0491 154.656 30.0729 153.622 30.6119 152.33C30.7696 151.952 31.2852 151.952 31.4435 152.33C31.982 153.622 33.0053 154.656 34.2837 155.2C34.6587 155.36 34.6587 155.88 34.2837 156.04C33.0053 156.584 31.982 157.618 31.4435 158.909C31.2852 159.288 30.7696 159.288 30.6119 158.909Z"
      fill="#83A9F9"
    />
    <path
      d="M236.801 92.0983C236.262 90.8072 235.237 89.7727 233.959 89.2288C233.584 89.069 233.584 88.5493 233.959 88.3894C235.237 87.8456 236.262 86.8111 236.801 85.5199C236.959 85.1418 237.474 85.1418 237.631 85.5199C238.17 86.8111 239.194 87.8456 240.473 88.3894C240.847 88.5493 240.847 89.069 240.473 89.2288C239.194 89.7727 238.17 90.8072 237.631 92.0983C237.474 92.4765 236.959 92.4765 236.801 92.0983Z"
      fill="#83A9F9"
    />
    <path
      opacity="0.3"
      d="M266.712 153.802C265.494 150.883 263.18 148.546 260.29 147.316C259.444 146.955 259.444 145.779 260.29 145.419C263.18 144.188 265.494 141.851 266.712 138.933C267.07 138.077 268.235 138.077 268.591 138.933C269.809 141.851 272.124 144.188 275.013 145.419C275.86 145.779 275.86 146.955 275.013 147.316C272.124 148.546 269.809 150.883 268.591 153.802C268.235 154.657 267.07 154.657 266.712 153.802Z"
      fill="#9BD81A"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1739_3998"
        x1="117.857"
        y1="87.2598"
        x2="226.277"
        y2="71.9731"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.72" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

SvgNoResult.defaultProps = defaultProps;

export default memo(SvgNoResult);
