import axios from "axios";
import { isEmpty } from "./validators";

export const fetchUrl = (url: string) => {
  const baseUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/${url}`;
  return baseUrl;
};

export const setAuthorization = (token: string) => {
  if (!isEmpty(token)) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};
