import { memo } from "react";

const defaultProps = {
  width: 58,
  height: 58,
};

const SvgLab1 = ({ width, height }: typeof defaultProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.5341 42.71L34.9159 18.005V8H36.3949C37.2083 8 37.8738 7.325 37.8738 6.5C37.8738 5.675 37.2083 5 36.3949 5H21.6051C20.7917 5 20.1262 5.675 20.1262 6.5C20.1262 7.325 20.7917 8 21.6051 8H23.0841V18.005L8.46592 42.71C5.11752 48.368 7.70276 53 14.2103 53H43.7897C50.2972 53 52.8825 48.371 49.5341 42.71ZM16.476 35L26.042 18.83V8H31.9579V18.83L41.524 35H16.476Z"
      fill="white"
    />
  </svg>
);

SvgLab1.defaultProps = defaultProps;

export default memo(SvgLab1);
