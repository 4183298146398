import { memo } from "react";

const defaultProps = {
  width: 32,
  height: 32,
};

const SvgBoxInfo = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" rx="4" fill="#F5F5FA" fillOpacity="0.1" />
    <path
      d="M16.2222 23.5C20.3643 23.5 23.7222 20.1421 23.7222 16C23.7222 11.8579 20.3643 8.5 16.2222 8.5C12.08 8.5 8.72217 11.8579 8.72217 16C8.72217 20.1421 12.08 23.5 16.2222 23.5Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2222 19.3333V16"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2222 12.667H16.2305"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

SvgBoxInfo.defaultProps = defaultProps;

export default memo(SvgBoxInfo);
