import { fetchUrl } from "../utils/apiConfig";

export const loginApi = fetchUrl(`users/login`);
export const otpValidationApi = fetchUrl(`users/login/otp/validation`);
export const forgotPasswordApi = fetchUrl(`users/forgot-password/otp`);
export const forgotPasswordValidationApi = fetchUrl(
  `users/forgot-password/otp/validation`
);
export const resetPasswordApi = fetchUrl(`users/reset-password`);
export const checkEmailApi = (email: string) =>
  fetchUrl(`users/email/${email}/is-exists`);
export const checkUserNameApi = (userName: string) =>
  fetchUrl(`users/userName/${userName}/is-exists`);
export const signUpApi = fetchUrl("users/signup");

// Profile Screen
export const getUserApi = fetchUrl("users");
export const uploadProfileApi = fetchUrl("users/upload/profile");

export const vimeoApi = fetchUrl(`vimeo`);

// Labs
export const getLabReportApi = fetchUrl(`lab-report`);
export const getFileApi = (id: string) => fetchUrl(`files/${id}`);
export const attachLabFileApi = (id: string) =>
  fetchUrl(`lab-report/${id}/upload/pdf-file`);

// H&P
export const historyPhysicalApi = fetchUrl("h-and-p-v1-logs");

// mailbag
export const mailBagApi = fetchUrl("emails/mailbag");
export const supportApi = fetchUrl(`emails/support`);

// ABCD's
export const userMedicalParametersApi = fetchUrl(`user-medical-parameters`);
export const userMedicalParametersV2Api = fetchUrl(
  `user-medical-parameters/v2`
);
// Zoom
export const getZoomApi = (id: string) => fetchUrl(`zoom/${id}`);
export const zoomNewSessionApi = fetchUrl(`zoom/new-session`);
export const zoomNewSessionTwoApi = fetchUrl(`zoom/new-session/v2`);

export const getZoomListApi = fetchUrl(`zoom`);
export const zoomCreateSignatureApi = (id: string) =>
  fetchUrl(`zoom/${id}/create-signature`);

// Todo
export const getTodoItemsApi = fetchUrl("todo/items");
export const getTodoListsApi = fetchUrl("todo");
export const updateTodoApi = (id: string) => fetchUrl(`todo/${id}`);
export const updateStatusCompleteApi = (id: string) =>
  fetchUrl(`todo/${id}/status/completed`);
export const updateStatusPendingApi = (id: string) =>
  fetchUrl(`todo/${id}/status/pending`);

export const createForumApi = fetchUrl(`discourse/create-discourse-user`);
export const updateForumPasswordApi = fetchUrl(`users/forum-password`);

export const subscribeApi = fetchUrl(`payments/subscribe/paid`);
export const getPaymentbyIdApi = (id: string) => fetchUrl(`payments/${id}`);
export const stripeSubscriptionsCancelApi = fetchUrl(
  `stripe-subscriptions/currenct-subscription/cancel`
);
export const couponValidateApi = (id: string) =>
  fetchUrl(`payments/coupon/${id}/is-valid`);

export const getSubscriptionsAllPlans = fetchUrl(
  `stripe-subscriptions/all-plans/v2`
);

export const getClinicsApi = fetchUrl(`clinics/fetch-all-v2`);
export const getClinicsIDApi = (id: string) => fetchUrl(`clinics/${id}`);
export const getTestosteronologistApi = (id: string) =>
  fetchUrl(`clinics/${id}/fetch-testosteronologist`);

export const testosteronologistConnectApi = (
  clinicId: string,
  userId: string
) =>
  fetchUrl(
    `testosteronologist-connect/clinic/${clinicId}/${userId}/email-trigger`
  );

export const usersArchiveApi = fetchUrl(`users/archive-user`);

export const getIAPSubscriptionApi = (id: string) =>
  fetchUrl(`iap-subscription/${id}`);

export const discourseSSOApi = fetchUrl(`discourse/sso`);

export const clinicSignUpApi = fetchUrl("clinics/signup");
export const publicFilesUploadApi = fetchUrl(`public-files/upload`);
export const affiliateLicensingMonthlyApi = fetchUrl(
  `payments/subscribe/testosteronology_affiliate_licensing_monthly`
);
export const affiliateLicensingAnnualApi = fetchUrl(
  `payments/subscribe/testosteronology_affiliate_licensing_annual`
);

export const getCurrentSubscriptionApi = fetchUrl(
  "stripe-subscriptions/current-subscription"
);

// Connect Testo

export const getTestoConnectApi = (status: string) =>
  fetchUrl(`testosteronologist-user-connect/testos/status/${status}`);

export const requestTestoConnectApi = (testoId: string, status: string) =>
  fetchUrl(
    `testosteronologist-user-connect/testos/${testoId}/status/${status}`
  );

export const getUserConnectApi = (status: string) =>
  fetchUrl(`testosteronologist-user-connect/users/status/${status}`);

export const requestUserConnectApi = (userId: string, status: string) =>
  fetchUrl(`testosteronologist-user-connect/users/${userId}/status/${status}`);
