import { memo } from "react";

const defaultProps = {
  width: 58,
  height: 58,
};

const SvgMail1 = ({ width, height }: typeof defaultProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 10.875C12.5772 10.875 10.7331 11.6388 9.37348 12.9985C8.01384 14.3581 7.25 16.2022 7.25 18.125V18.8536L29 30.566L50.75 18.8573V18.125C50.75 16.2022 49.9862 14.3581 48.6265 12.9985C47.2669 11.6388 45.4228 10.875 43.5 10.875H14.5ZM50.75 22.9716L29.8591 34.22C29.5951 34.3621 29.2999 34.4366 29 34.4366C28.7001 34.4366 28.4049 34.3621 28.1409 34.22L7.25 22.9716V39.875C7.25 41.7978 8.01384 43.6419 9.37348 45.0015C10.7331 46.3612 12.5772 47.125 14.5 47.125H43.5C45.4228 47.125 47.2669 46.3612 48.6265 45.0015C49.9862 43.6419 50.75 41.7978 50.75 39.875V22.9716Z"
      fill="white"
    />
  </svg>
);

SvgMail1.defaultProps = defaultProps;

export default memo(SvgMail1);
