import { createSlice } from "@reduxjs/toolkit";
import {
  GetClinicsIDReducerState,
  GetClinicsReducerState,
  TestosteronologistsReducerState,
} from "./findprovider.types";
import {
  getClinicsIDMiddleWare,
  getClinicsMiddleWare,
  getTestosteronologistMiddleWare,
} from "./findproviderMiddleware";

const getClinicsInitialState: GetClinicsReducerState = {
  isLoading: false,
  error: "",
};

const getClinicsReducer = createSlice({
  name: "get_clinics",
  initialState: getClinicsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClinicsMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClinicsMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getClinicsMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

const getClinicsIDInitialState: GetClinicsIDReducerState = {
  isLoading: false,
  error: "",
};

const getClinicsIDReducer = createSlice({
  name: "get_clinics_id",
  initialState: getClinicsIDInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClinicsIDMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClinicsIDMiddleWare.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getClinicsIDMiddleWare.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

const getTestosteronologistInitialState: TestosteronologistsReducerState = {
  isLoading: false,
  error: "",
};

const getTestosteronologistReducer = createSlice({
  name: "get_testosteronologist",
  initialState: getTestosteronologistInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTestosteronologistMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getTestosteronologistMiddleWare.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(
      getTestosteronologistMiddleWare.rejected,
      (state, action) => {
        state.isLoading = false;
        if (typeof action.payload === "string") {
          state.error = action.payload;
        }
      }
    );
  },
});

export const getClinicsReducers = getClinicsReducer.reducer;
export const getClinicsIDReducers = getClinicsIDReducer.reducer;
export const getTestosteronologistReducers =
  getTestosteronologistReducer.reducer;
