import { memo } from "react";

const defaultProps = {
  width: 75,
  height: 75,
};

const SvgLogo = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 75 75" fill="none">
    <path
      d="M63.09 74.9991H0V11.8984H20.7024V23.8084H11.8994V63.0997H51.1906V53.4935H63.09V74.9991Z"
      fill="white"
    />
    <path
      d="M75.0002 39.2913H63.0903V11.8994H35.709V0H75.0002V39.2913Z"
      fill="#00B5D1"
    />
    <path
      d="M42.0175 41.9764L33.6055 33.5538L63.0898 3.48828L71.5123 11.9003L42.0175 41.9764Z"
      fill="#00B5D1"
    />
  </svg>
);

SvgLogo.defaultProps = defaultProps;

export default memo(SvgLogo);
