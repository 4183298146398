import {createSlice} from '@reduxjs/toolkit';
import {UserMedicalReducerState} from './abcds.types';
import {getUserMedicalParametersMiddleWare} from './abcdsMiddleware';

const getUserInitialState: UserMedicalReducerState = {
  isLoading: false,
  error: '',
  userMedicalParameters: {
    _id: '',
    user: '',
    __v: 0,
    createdAt: '',
    updatedAt: '',
  },
};

const getUserMedicalParametersReducer = createSlice({
  name: 'getUserMedicalParameters',
  initialState: getUserInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUserMedicalParametersMiddleWare.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      getUserMedicalParametersMiddleWare.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.userMedicalParameters = action.payload?.userMedicalParameters;
      },
    );
    builder.addCase(
      getUserMedicalParametersMiddleWare.rejected,
      (state, action) => {
        state.isLoading = false;
        if (typeof action.payload === 'string') {
          state.error = action.payload;
        }
      },
    );
  },
});

export const getUserMedicalParametersReducers =
  getUserMedicalParametersReducer.reducer;
