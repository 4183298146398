import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
  fill: "#50BDB2",
  focused: false,
};

const SvgBilling = ({ width, height, fill, focused }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <g opacity={focused ? 1 : "0.5"}>
      <path
        d="M3 8C3 6.34315 4.34315 5 6 5H18C19.6569 5 21 6.34315 21 8V16C21 17.6569 19.6569 19 18 19H6C4.34315 19 3 17.6569 3 16V8Z"
        stroke={fill}
        strokeWidth="2"
      />
      <path d="M3 10H21" stroke={fill} strokeWidth="2" />
      <path
        d="M14 15L17 15"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

SvgBilling.defaultProps = defaultProps;

export default memo(SvgBilling);
