import { SxProps, Theme } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { CountryCode } from "libphonenumber-js";
import LabelWrapper from "../LabelWrapper/LabelWrapper";

type Props = {
  label?: string;
  labelColor?: string;
  labelSize?: number;
  error?: boolean;
  onChange?: any;
  value?: string;
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  labelClassName?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  helperText?: string;
  defaultCountry?: CountryCode;
};

const InputPhone = ({
  label,
  labelSize,
  labelColor,
  error,
  onChange,
  value,
  placeholder,
  fullWidth,
  sx,
  labelClassName,
  labelSx,
  required,
  disabled,
  name,
  helperText,
  defaultCountry = "US",
}: Props) => {
  return (
    <LabelWrapper
      required={required}
      labelSx={labelSx}
      labelClassName={labelClassName}
      label={label}
      labelColor={labelColor}
      labelSize={labelSize}
    >
      <MuiTelInput
        InputProps={{
          style: {
            borderRadius: "12px",
          },
        }}
        defaultCountry={defaultCountry}
        error={error}
        name={name}
        helperText={helperText}
        placeholder={placeholder}
        fullWidth={fullWidth}
        sx={sx}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </LabelWrapper>
  );
};

export default InputPhone;
