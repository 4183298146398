import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ATTACH_LAB,
  GET_FILE,
  MY_LAB_GET_REPORT,
  UPLOAD_LAB,
} from "../../../redux/actions";
import {
  getLabReportApi,
  getFileApi,
  attachLabFileApi,
} from "../../../routes/apiRoutes";

export const getLabReportMiddleWare = createAsyncThunk(
  MY_LAB_GET_REPORT,
  async ({
    page,
    pageSize,
    search,
  }: {
    page: number;
    pageSize: number;
    search?: any;
  }) => {
    try {
      const { data } = await axios.get(getLabReportApi, {
        params: {
          page,
          pageSize,
          search,
        },
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const uploadLabMiddleWare = createAsyncThunk(
  UPLOAD_LAB,
  async ({
    reportTakenOn,
    reportTitle,
    reportDescription,
  }: {
    reportTakenOn: string;
    reportTitle: string;
    reportDescription: string;
  }) => {
    try {
      const { data } = await axios.post(getLabReportApi, {
        reportTakenOn,
        reportTitle,
        reportDescription,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const attachLabMiddleWare = createAsyncThunk(
  ATTACH_LAB,
  async ({ id, formData }: { id: string; formData: any }) => {
    try {
      const { data } = await axios.post(attachLabFileApi(id), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getFileMiddleWare = createAsyncThunk(
  GET_FILE,
  async ({ id }: { id: any }) => {
    try {
      const { data } = await axios.get(getFileApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
