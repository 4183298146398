import { memo } from "react";

const defaultProps = {
  width: 58,
  height: 58,
};

const SvgVideoHome = ({ width, height }: typeof defaultProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.667 9.66675C39.3079 9.66675 39.9226 9.92136 40.3758 10.3746C40.829 10.8278 41.0837 11.4425 41.0837 12.0834V22.2334L53.6817 13.4126C53.8629 13.2856 54.0754 13.2108 54.2962 13.1963C54.5169 13.1818 54.7374 13.2282 54.9336 13.3304C55.1298 13.4326 55.2942 13.5867 55.4088 13.7759C55.5234 13.9651 55.5839 14.1822 55.5837 14.4034V43.5967C55.5839 43.818 55.5234 44.035 55.4088 44.2242C55.2942 44.4134 55.1298 44.5675 54.9336 44.6698C54.7374 44.772 54.5169 44.8184 54.2962 44.8039C54.0754 44.7894 53.8629 44.7146 53.6817 44.5876L41.0837 35.7667V45.9167C41.0837 46.5577 40.829 47.1724 40.3758 47.6256C39.9226 48.0788 39.3079 48.3334 38.667 48.3334H4.83366C4.19272 48.3334 3.57803 48.0788 3.12482 47.6256C2.6716 47.1724 2.41699 46.5577 2.41699 45.9167V12.0834C2.41699 11.4425 2.6716 10.8278 3.12482 10.3746C3.57803 9.92136 4.19272 9.66675 4.83366 9.66675H38.667ZM17.8837 21.3368C17.6608 21.3368 17.4448 21.4137 17.2722 21.5547C17.0995 21.6956 16.9809 21.8918 16.9363 22.1102L16.917 22.3011V35.6942C16.917 35.8508 16.955 36.005 17.0277 36.1436C17.1005 36.2822 17.2058 36.4011 17.3346 36.49C17.4635 36.5789 17.612 36.6352 17.7674 36.654C17.9228 36.6729 18.0805 36.6537 18.2268 36.5981L18.4032 36.5111L28.9278 29.8145C29.0507 29.7357 29.1541 29.6301 29.2305 29.5057C29.3068 29.3813 29.3541 29.2413 29.3687 29.0961C29.3833 28.9508 29.365 28.8042 29.315 28.667C29.265 28.5299 29.1847 28.4058 29.0801 28.3041L28.9278 28.1832L18.4032 21.4842C18.2473 21.3876 18.0671 21.3349 17.8837 21.3368Z"
      fill="white"
    />
  </svg>
);

SvgVideoHome.defaultProps = defaultProps;

export default memo(SvgVideoHome);
