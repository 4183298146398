import { connect } from "react-redux";
import { handleHistoryPhysicalPost } from "./HistoryPhysical1";
import { RootState, useAppDispatch } from "../../redux/store";
import { HealthAndPhysicalExamV1Logs } from "./store/historyPhysicalTypes";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routesPath";
import HPContainer from "../../common/HPContainer";
import { Box, Typography } from "@mui/material";
import { FONTS } from "../../utils/fontConstant";
import { Flex } from "../../packages";
import { GREY_TEXT_1, PRIMARY_LIGHTER } from "../../theme/colors";

const data = [
  {
    value: 1,
    label:
      "Are you currently on androgens, anabolic steroids, sarms, or any other potentially suppressive agents?",
  },
  {
    value: 2,
    label:
      "Do you have symptoms of low testosterone, such as erectile dysfunction, low sex drive, loss of muscle and/or strength, or fatigue?",
  },
  {
    value: 3,
    label: " Are you age 40 or older?",
  },
  {
    value: 4,
    label:
      " Have you had a blood test indicating you may have low testosterone?",
  },
];

export const HPList = ({ name }: { name: string }) => {
  return (
    <Box display="flex" flexDirection={"row"} sx={{ mb: 1 }}>
      <div
        style={{
          height: 10,
          width: 10,
          borderRadius: 100,
          marginRight: 8,
          backgroundColor: PRIMARY_LIGHTER,
          marginTop: 6,
        }}
      />
      <Typography fontSize={14} fontFamily={FONTS.MONTSERRAT_SEMIBOLD}>
        {name}
      </Typography>
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  const { getHistoryPhysicalReducers } = state;
  return {
    healthAndPhysicalExamV1Logs:
      getHistoryPhysicalReducers.data?.healthAndPhysicalExamV1Logs,
  };
};

type Props = {
  healthAndPhysicalExamV1Logs?: HealthAndPhysicalExamV1Logs;
};

const HistoryPhysical6 = ({ healthAndPhysicalExamV1Logs }: Props) => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  // const [isCheck, setCheck] = useState<any>([]);

  // useEffect(() => {
  //   if (
  //     healthAndPhysicalExamV1Logs &&
  //     Array(healthAndPhysicalExamV1Logs?.pageDetails) &&
  //     healthAndPhysicalExamV1Logs.pageDetails.length > 0
  //   ) {
  //     const filterCheckValue = healthAndPhysicalExamV1Logs.pageDetails.filter(
  //       (list) => list?.otherDetails?.page === "HistoryPhysical6"
  //     );
  //     if (filterCheckValue.length > 0) {
  //       setCheck(filterCheckValue[0].otherDetails.value);
  //     }
  //   } else {
  //     setCheck([]);
  //   }
  // }, [healthAndPhysicalExamV1Logs?.pageDetails]);

  // const handleOnClick = (item: any) => {
  //   const updatedSelectedOptions = isCheck.includes(item.value)
  //     ? isCheck.filter((ids: any) => ids !== item.value)
  //     : [...isCheck, item.value];
  //   setCheck(updatedSelectedOptions);
  // };

  // useEffect(() => {
  //   dispatch(setLoader(true));
  //   dispatch(getHistoryPhysicalMiddleWare()).then(() =>
  //     dispatch(setLoader(false))
  //   );
  // }, []);

  // const handleSubmit = () => {
  //   handleHistoryPhysicalPost({
  //     dispatch,
  //     healthAndPhysicalExamV1Logs,
  //     isCheck: [],
  //     navigation,
  //     page: routes.HISTORY_PHYSICAL_6,
  //     currentPage: "10",
  //     navigate: routes.HISTORY_PHYSICAL_10,
  //   });
  // };

  const handleSubmit = () => {
    handleHistoryPhysicalPost({
      dispatch,
      healthAndPhysicalExamV1Logs,
      isCheck: [],
      navigation,
      page: routes.HISTORY_PHYSICAL_6,
      currentPage: "7",
      navigate: routes.HISTORY_PHYSICAL_7,
    });
  };
  return (
    <HPContainer title="Androgen Status" handleSubmit={handleSubmit}>
      <Flex>
        {data.map((list) => (
          <HPList name={list.label} />
        ))}
      </Flex>
      <Typography
        component={"pre"}
        sx={{ whiteSpace: "pre-wrap" }}
        color={GREY_TEXT_1}
        fontSize={16}
        fontFamily={FONTS.SOURCESANSPRO_REGULAR}
      >
        <br />
        <br />
        If you answered yes to any of the above or are curious about
        testosterone replacement therapy or low testosterone, we recommend you
        select "Learn More". If you would like to skip this section, select
        "Skip to Next".
      </Typography>
    </HPContainer>
  );
};

export default connect(mapStateToProps)(HistoryPhysical6);
