import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  CREATE_TODO_ITEMS,
  DELETE_TODO_ITEMS,
  GET_BY_ID_TODO_ITEMS,
  GET_TODO_ITEMS,
  GET_TODO_LISTS,
  PENDING_TODO_ITEMS,
  UPDATE_TODO_ITEMS,
} from "../../../redux/actions";
import {
  getTodoItemsApi,
  getTodoListsApi,
  updateStatusCompleteApi,
  updateStatusPendingApi,
  updateTodoApi,
} from "../../../routes/apiRoutes";

export const getTodoItemsMiddleWare = createAsyncThunk(
  GET_TODO_ITEMS,
  async (_a) => {
    try {
      const { data } = await axios.get(getTodoItemsApi);
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getTodoListMiddleWare = createAsyncThunk(
  GET_TODO_LISTS,
  async ({
    status,
    page,
    pageSize,
  }: {
    status?: "pending" | "completed";
    page: number;
    pageSize: number;
  }) => {
    try {
      const { data } = await axios.get(getTodoListsApi, {
        params: {
          status,
          page,
          pageSize,
        },
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const createTodoMiddleWare = createAsyncThunk(
  CREATE_TODO_ITEMS,
  async ({ item, scheduledAt }: { item: string; scheduledAt: any }) => {
    try {
      const { data } = await axios.post(getTodoListsApi, { item, scheduledAt });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const updateTodoMiddleWare = createAsyncThunk(
  UPDATE_TODO_ITEMS,
  async ({
    item,
    scheduledAt,
    id,
  }: {
    item: string;
    scheduledAt: string;
    id: string;
  }) => {
    try {
      const { data } = await axios.put(updateTodoApi(id), {
        item,
        scheduledAt,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const completeTodoMiddleWare = createAsyncThunk(
  UPDATE_TODO_ITEMS,
  async ({ id }: { id: string }) => {
    try {
      const { data } = await axios.put(updateStatusCompleteApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const pendingTodoMiddleWare = createAsyncThunk(
  PENDING_TODO_ITEMS,
  async ({ id }: { id: string }) => {
    try {
      const { data } = await axios.put(updateStatusPendingApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getTodoByIdMiddleWare = createAsyncThunk(
  GET_BY_ID_TODO_ITEMS,
  async ({ id }: { id: string }) => {
    try {
      const { data } = await axios.get(updateTodoApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const deleteTodoMiddleWare = createAsyncThunk(
  DELETE_TODO_ITEMS,
  async ({ id }: { id: string }) => {
    try {
      const { data } = await axios.delete(updateTodoApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
