import { memo } from "react";

const defaultProps = {
  width: 288,
  height: 33,
};

const SvgLogoText = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 288 33" fill="none">
    <path
      d="M27.2633 32.4099H0V5.14208H8.9462V10.2888H5.14212V27.2678H22.1212V23.1167H27.2633V32.4099Z"
      fill="white"
    />
    <path
      d="M32.41 16.979H27.2633V5.14208H15.4309V-4.57764e-05H32.41V16.979Z"
      fill="#00B5D1"
    />
    <path
      d="M18.1571 18.139L14.522 14.4993L27.2631 1.50699L30.9028 5.1421L18.1571 18.139Z"
      fill="#00B5D1"
    />
    <path
      d="M47.2792 20.4863H43.3701V17.3718H54.7732V20.4863H50.8413V32.4054H47.2747V20.4863H47.2792Z"
      fill="white"
    />
    <path
      d="M58.8743 17.3717H69.227V20.4634H62.4409V23.1897H67.8753V26.2403H62.4409V29.3137H69.227V32.4054H58.8743V17.3717Z"
      fill="white"
    />
    <path
      d="M73.1772 29.4416V27.4642H76.6982V28.9895C76.6982 29.2909 76.8032 29.3959 77.1046 29.3959H80.4977C80.7991 29.3959 80.9041 29.2909 80.9041 28.9895V27.4231C80.9041 27.0806 80.7534 26.971 80.3013 26.866L76.3055 25.8978C74.3098 25.4046 73.2138 24.8429 73.2138 22.8061V20.3355C73.2138 18.3171 74.1819 17.3717 76.1776 17.3717H81.2466C83.2423 17.3717 84.2104 18.3171 84.2104 20.3355V22.1394H80.6895V20.7877C80.6895 20.468 80.5845 20.3812 80.2831 20.3812H77.1914C76.89 20.3812 76.785 20.468 76.785 20.7877V22.1851C76.785 22.5276 76.9128 22.6372 77.3878 22.7422L81.3836 23.7103C83.3793 24.2036 84.4753 24.7653 84.4753 26.802V29.4416C84.4753 31.4372 83.5072 32.4054 81.5115 32.4054H76.141C74.1454 32.4054 73.1772 31.4372 73.1772 29.4416Z"
      fill="white"
    />
    <path
      d="M91.8366 20.4862H87.9275V17.3717H99.3352V20.4862H95.4032V32.4054H91.8366V20.4862Z"
      fill="white"
    />
    <path
      d="M102.897 29.4416V20.3355C102.897 18.3171 103.866 17.3717 105.861 17.3717H111.638C113.657 17.3717 114.602 18.3171 114.602 20.3355V29.4416C114.602 31.4372 113.657 32.4054 111.638 32.4054H105.861C103.866 32.4054 102.897 31.4372 102.897 29.4416ZM110.629 29.3137C110.949 29.3137 111.035 29.2087 111.035 28.9073V20.8744C111.035 20.5547 110.949 20.468 110.629 20.468H106.871C106.569 20.468 106.464 20.5547 106.464 20.8744V28.9073C106.464 29.2087 106.569 29.3137 106.871 29.3137H110.629Z"
      fill="white"
    />
    <path
      d="M119.022 29.4416V27.4642H122.543V28.9895C122.543 29.2909 122.653 29.3959 122.954 29.3959H126.347C126.649 29.3959 126.754 29.2909 126.754 28.9895V27.4231C126.754 27.0806 126.603 26.971 126.151 26.866L122.155 25.8978C120.16 25.4046 119.063 24.8429 119.063 22.8061V20.3355C119.063 18.3171 120.032 17.3717 122.027 17.3717H127.096C129.092 17.3717 130.06 18.3171 130.06 20.3355V22.1394H126.539V20.7877C126.539 20.468 126.43 20.3812 126.128 20.3812H123.037C122.735 20.3812 122.626 20.468 122.626 20.7877V22.1851C122.626 22.5276 122.753 22.6372 123.228 22.7422L127.224 23.7103C129.22 24.2036 130.316 24.7653 130.316 26.802V29.4416C130.316 31.4372 129.348 32.4054 127.352 32.4054H121.982C119.986 32.4054 119.018 31.4372 119.018 29.4416H119.022Z"
      fill="white"
    />
    <path
      d="M137.687 20.4862H133.778V17.3717H145.181V20.4862H141.249V32.4054H137.682V20.4862H137.687Z"
      fill="white"
    />
    <path
      d="M149.281 17.3717H159.634V20.4634H152.848V23.1897H158.282V26.2403H152.848V29.3137H159.634V32.4054H149.281V17.3717Z"
      fill="white"
    />
    <path
      d="M176.084 32.4054H172.366L169.855 27.2267H167.772V32.4054H164.206V17.3717H172.581C174.577 17.3717 175.545 18.3171 175.545 20.3355V24.094C175.545 25.7928 174.878 26.7152 173.485 26.971L176.084 32.4054ZM171.572 24.2629C171.873 24.2629 171.978 24.1762 171.978 23.8565V20.8059C171.978 20.4862 171.873 20.3995 171.572 20.3995H167.772V24.2675H171.572V24.2629Z"
      fill="white"
    />
    <path
      d="M180.093 29.4416V20.3355C180.093 18.3171 181.061 17.3717 183.057 17.3717H188.834C190.852 17.3717 191.798 18.3171 191.798 20.3355V29.4416C191.798 31.4372 190.852 32.4054 188.834 32.4054H183.057C181.061 32.4054 180.093 31.4372 180.093 29.4416ZM187.825 29.3137C188.144 29.3137 188.231 29.2087 188.231 28.9073V20.8744C188.231 20.5547 188.144 20.468 187.825 20.468H184.066C183.765 20.468 183.66 20.5547 183.66 20.8744V28.9073C183.66 29.2087 183.765 29.3137 184.066 29.3137H187.825Z"
      fill="white"
    />
    <path
      d="M196.67 17.3717H200.301L205.607 26.1992V17.3717H209.064V32.4054H205.758L200.132 23.0847V32.4054H196.675V17.3717H196.67Z"
      fill="white"
    />
    <path
      d="M213.937 29.4416V20.3355C213.937 18.3171 214.901 17.3717 216.901 17.3717H222.678C224.696 17.3717 225.642 18.3171 225.642 20.3355V29.4416C225.642 31.4372 224.696 32.4054 222.678 32.4054H216.901C214.905 32.4054 213.937 31.4372 213.937 29.4416ZM221.668 29.3137C221.988 29.3137 222.075 29.2087 222.075 28.9073V20.8744C222.075 20.5547 221.988 20.468 221.668 20.468H217.91C217.609 20.468 217.504 20.5547 217.504 20.8744V28.9073C217.504 29.2087 217.609 29.3137 217.91 29.3137H221.668Z"
      fill="white"
    />
    <path
      d="M230.514 17.3717H234.081V29.2726H240.333V32.4099H230.519V17.3763L230.514 17.3717Z"
      fill="white"
    />
    <path
      d="M243.895 29.4416V20.3355C243.895 18.3171 244.858 17.3717 246.859 17.3717H252.635C254.654 17.3717 255.599 18.3171 255.599 20.3355V29.4416C255.599 31.4372 254.654 32.4054 252.635 32.4054H246.859C244.863 32.4054 243.895 31.4372 243.895 29.4416ZM251.626 29.3137C251.946 29.3137 252.033 29.2087 252.033 28.9073V20.8744C252.033 20.5547 251.946 20.468 251.626 20.468H247.868C247.566 20.468 247.461 20.5547 247.461 20.8744V28.9073C247.461 29.2087 247.566 29.3137 247.868 29.3137H251.626Z"
      fill="white"
    />
    <path
      d="M260.193 29.4416V20.3355C260.193 18.3171 261.157 17.3717 263.157 17.3717H268.719C270.715 17.3717 271.683 18.3171 271.683 20.3355V22.3312H268.139V20.847C268.139 20.5273 268.03 20.4406 267.733 20.4406H264.166C263.865 20.4406 263.76 20.5273 263.76 20.847V28.9255C263.76 29.2269 263.865 29.332 264.166 29.332H267.733C268.034 29.332 268.139 29.2224 268.139 28.9255V26.8203H265.605V23.8793H271.683V29.4416C271.683 31.4372 270.715 32.4054 268.719 32.4054H263.157C261.161 32.4054 260.193 31.4372 260.193 29.4416Z"
      fill="white"
    />
    <path
      d="M279.602 27.5099L274.748 17.3717H278.616L281.406 23.9022L284.178 17.3717H288L283.169 27.5099V32.4054H279.602V27.5099Z"
      fill="white"
    />
  </svg>
);

SvgLogoText.defaultProps = defaultProps;

export default memo(SvgLogoText);
