import { Box, SxProps, Theme, Typography } from "@mui/material";
import { memo } from "react";
import { FONTS } from "../../utils/fontConstant";
import { PRIMARY, WHITE } from "../../theme/colors";

type Props = {
  required?: boolean;
  children?: import("react").ReactNode;
  label?: string;
  labelSize?: number;
  labelColor?: string;
  labelFontFamily?: string;
  isHtml?: boolean;
  labelSx?: SxProps<Theme>;
  labelClassName?: string;
};

const LabelWrapper = ({
  required,
  children,
  label,
  labelFontFamily = FONTS.MONTSERRAT_SEMIBOLD,
  labelSize = 14,
  labelColor = WHITE,
  labelSx,
  labelClassName,
}: Props) => {
  return (
    <Box sx={labelSx} className={labelClassName}>
      <Box sx={{ mb: 1 }} display="flex" flexDirection={"row"}>
        <Typography
          fontSize={labelSize}
          fontFamily={labelFontFamily}
          color={labelColor}
        >
          {label}
        </Typography>
        {required && (
          <Typography
            sx={{ ml: 0.3 }}
            fontFamily={labelFontFamily}
            fontSize={labelSize}
            color={PRIMARY}
          >
            *
          </Typography>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default memo(
  LabelWrapper,
  (prevProps: Props, nextProps: Props) =>
    prevProps.children === nextProps.children
);
