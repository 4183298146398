import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 25,
};

const SvgHeart = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 24 25" fill="none">
    <path
      d="M17.1389 4.38838C16.5998 4.20854 16.0328 4.12891 15.4649 4.12891C14 4.1289 12.9999 4.86913 12 5.62875C11.0176 4.88 10 4.12891 8.55296 4.12891C7.99669 4.12891 7.41614 4.18883 6.88887 4.36959C3.53101 5.46205 2.33402 9.08571 3.35101 12.253C3.92701 13.8716 4.8549 15.3399 6.06088 16.5469C7.87741 18.2888 9.85555 19.8086 12 21.1288C14.1404 19.8435 16.1547 18.284 17.9498 16.5558C19.1638 15.3488 20.0908 13.8716 20.6578 12.253C21.6577 9.08571 20.4607 5.46205 17.1389 4.38838Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

SvgHeart.defaultProps = defaultProps;

export default memo(SvgHeart);
