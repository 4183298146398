import { memo } from "react";

const defaultProps = {
  width: 18,
  height: 20,
  fill: "#000",
};

const SvgConsultation = ({ width, height, fill }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 204.877 204.877" fill="none">
    <path
      fill={fill}
      d="M111.39 0H93.487C44.284 0 4.255 40.029 4.255 89.232v48.18a5 5 0 0 0 5 5h7.505v3.521a5 5 0 0 0 5 5h9.074c8.971 0 16.269-7.298 16.269-16.269V95.589c0-8.971-7.298-16.269-16.269-16.269H21.76a5 5 0 0 0-5 5v3.522h-2.47C15.037 44.795 50.264 10 93.487 10h17.903c43.222 0 78.45 34.795 79.197 77.843h-2.47V84.32a5 5 0 0 0-5-5h-9.073c-8.971 0-16.269 7.298-16.269 16.269v39.077c0 8.971 7.298 16.269 16.269 16.269h9.073a4.98 4.98 0 0 0 3.543-1.474v10.792c0 14.475-11.776 26.25-26.25 26.25h-29.729c-1.985-4.903-6.789-8.375-12.396-8.375h-13.5c-7.375 0-13.375 6-13.375 13.375s6 13.375 13.375 13.375h13.5c5.607 0 10.411-3.472 12.396-8.375h29.729c19.988 0 36.25-16.262 36.25-36.25v-17.95a5 5 0 0 0 3.962-4.889V89.233C200.622 40.029 160.593 0 111.39 0"
    />
  </svg>
);

SvgConsultation.defaultProps = defaultProps;

export default memo(SvgConsultation);
