import { handleHistoryPhysicalPost } from "./HistoryPhysical1";
import { RootState, useAppDispatch } from "../../redux/store";
import { connect } from "react-redux";
import { HealthAndPhysicalExamV1Logs } from "./store/historyPhysicalTypes";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routesPath";
import { Typography } from "@mui/material";
import HPContainer from "../../common/HPContainer";
import { Flex } from "../../packages";
import { FONTS } from "../../utils/fontConstant";
import { GREY_TEXT_1 } from "../../theme/colors";
import { HPList } from "./HistoryPhysical6";

const data = [
  { label: "Do you use or have you used anabolic steroids?", value: 1 },
  { label: "Do you have high blood pressure?", value: 2 },
  {
    label: "Do you smoke, drink excessively, or do you eat an unhealthy diet?",
    value: 3,
  },
  { label: "Do you have a family history of stroke?", value: 4 },
  { label: "Are you overweight or do you have diabetes?", value: 5 },
  { label: "Do you have arterial plaque or abnormal lipids?", value: 6 },
];

const mapStateToProps = (state: RootState) => {
  const { getHistoryPhysicalReducers } = state;
  return {
    healthAndPhysicalExamV1Logs:
      getHistoryPhysicalReducers.data?.healthAndPhysicalExamV1Logs,
  };
};

type Props = {
  healthAndPhysicalExamV1Logs?: HealthAndPhysicalExamV1Logs;
};
const HistoryPhysical22A = ({ healthAndPhysicalExamV1Logs }: Props) => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  // const [isCheck, setCheck] = useState<any>([]);

  // useEffect(() => {
  //   if (
  //     healthAndPhysicalExamV1Logs &&
  //     Array(healthAndPhysicalExamV1Logs?.pageDetails) &&
  //     healthAndPhysicalExamV1Logs.pageDetails.length > 0
  //   ) {
  //     const filterCheckValue = healthAndPhysicalExamV1Logs.pageDetails.filter(
  //       (list) => list?.otherDetails?.page === "HistoryPhysical22A"
  //     );
  //     if (filterCheckValue.length > 0) {
  //       setCheck(filterCheckValue[0].otherDetails.value);
  //     }
  //   } else {
  //     setCheck([]);
  //   }
  // }, [healthAndPhysicalExamV1Logs?.pageDetails]);

  // const handleOnClick = (item: any) => {
  //   const updatedSelectedOptions = isCheck.includes(item.value)
  //     ? isCheck.filter((ids: any) => ids !== item.value)
  //     : [...isCheck, item.value];
  //   setCheck(updatedSelectedOptions);
  // };

  // useEffect(() => {
  //   dispatch(setLoader(true));
  //   dispatch(getHistoryPhysicalMiddleWare()).then(() =>
  //     dispatch(setLoader(false))
  //   );
  // }, []);

  const handleSubmit = () => {
    handleHistoryPhysicalPost({
      dispatch,
      healthAndPhysicalExamV1Logs,
      isCheck: [],
      navigation,
      page: routes.HISTORY_PHYSICAL_22A,
      currentPage: "23",
      navigate: routes.HISTORY_PHYSICAL_23,
    });
  };
  return (
    <HPContainer title="Stroke" handleSubmit={handleSubmit}>
      <Flex>
        {data.map((list) => (
          <HPList name={list.label} />
        ))}
      </Flex>
      <br />
      <br />
      <Typography
        component={"pre"}
        sx={{ whiteSpace: "pre-wrap" }}
        color={GREY_TEXT_1}
        fontSize={16}
        fontFamily={FONTS.SOURCESANSPRO_REGULAR}
      >
        {`We recommend all men complete the content on this portion of the history and physical, particularly if you answered yes to any of the above. If you would like to skip this section, select "Skip to Next."`}
      </Typography>
    </HPContainer>
  );
};

export default connect(mapStateToProps)(HistoryPhysical22A);
