import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
  focused: false,
};

const SvgForum = ({ width, height, focused }: typeof defaultProps) =>
  focused ? (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.99996C11.5404 5.99996 11.0852 6.09049 10.6606 6.26639C10.236 6.44228 9.85013 6.70009 9.52513 7.02509C9.20012 7.3501 8.94231 7.73593 8.76642 8.16057C8.59053 8.58521 8.5 9.04034 8.5 9.49996C8.5 9.95959 8.59053 10.4147 8.76642 10.8394C8.94231 11.264 9.20012 11.6498 9.52513 11.9748C9.85013 12.2998 10.236 12.5577 10.6606 12.7335C11.0852 12.9094 11.5404 13 12 13C12.9283 13 13.8185 12.6312 14.4749 11.9748C15.1313 11.3185 15.5 10.4282 15.5 9.49996C15.5 8.57171 15.1313 7.68147 14.4749 7.02509C13.8185 6.36871 12.9283 5.99996 12 5.99996ZM10.5 14C9.43913 14 8.42172 14.4214 7.67157 15.1715C6.92143 15.9217 6.5 16.9391 6.5 18C6.5 19.1 7.4 20 8.5 20H15.5C16.0304 20 16.5391 19.7893 16.9142 19.4142C17.2893 19.0391 17.5 18.5304 17.5 18C17.5 16.9391 17.0786 15.9217 16.3284 15.1715C15.5783 14.4214 14.5609 14 13.5 14H10.5ZM17.3 10.9C17.6213 9.68798 17.5188 8.40259 17.0096 7.2568C16.5004 6.11102 15.6149 5.17363 14.5 4.59996C15.1 4.19996 15.8 3.99996 16.5 3.99996C17.3532 4.00731 18.1744 4.32609 18.8091 4.89637C19.4438 5.46665 19.8483 6.24915 19.9466 7.09674C20.0448 7.94433 19.8301 8.79862 19.3427 9.499C18.8554 10.1994 18.1289 10.6976 17.3 10.9ZM19.5 18H20C20.5304 18 21.0391 17.7893 21.4142 17.4142C21.7893 17.0391 22 16.5304 22 16C22 14.9391 21.5786 13.9217 20.8284 13.1715C20.0783 12.4214 19.0609 12 18 12H16.9L16.4 12.8C18.3 13.8 19.5 15.8 19.5 18ZM4 7.49996C3.9949 6.8581 4.16641 6.2272 4.49578 5.67626C4.82514 5.12531 5.29967 4.67556 5.86746 4.37618C6.43525 4.0768 7.07443 3.93932 7.7151 3.97879C8.35577 4.01826 8.97325 4.23315 9.5 4.59996C8.36423 5.17732 7.46414 6.13108 6.95346 7.29835C6.44278 8.46562 6.35319 9.77398 6.7 11C5.91602 10.8162 5.21938 10.3677 4.72754 9.73015C4.2357 9.09258 3.97875 8.30492 4 7.49996ZM7.1 12H6C4.93913 12 3.92172 12.4214 3.17157 13.1715C2.42143 13.9217 2 14.9391 2 16C2 17.1 2.9 18 4 18H4.5C4.49932 16.9461 4.77624 15.9106 5.30289 14.9978C5.82953 14.0849 6.58732 13.3269 7.5 12.8L7.1 12Z"
        // fill="url(#paint0_linear_569_1016)"
        fill="#35D7F3"
      />
      <defs>
        <linearGradient
          id="paint0_linear_569_1016"
          x1="22"
          y1="3.97217"
          x2="1.71025"
          y2="7.35433"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35D7F3" />
          <stop offset="1" stopColor="#116B7A" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g opacity="0.5">
        <path
          d="M18.1111 21C18.1111 19.0131 15.3746 17.4028 12 17.4028C8.62544 17.4028 5.88889 19.0131 5.88889 21M23 17.4028C23 15.9279 21.4918 14.6605 19.3333 14.1053M1 17.4028C1 15.9279 2.50822 14.6605 4.66667 14.1053M19.3333 9.29227C19.6954 8.97823 19.9907 8.59705 20.2021 8.17066C20.4135 7.74427 20.5369 7.28109 20.5652 6.80775C20.5934 6.33441 20.5261 5.86026 20.3669 5.41255C20.2077 4.96485 19.9599 4.55244 19.6377 4.19903C19.3155 3.84562 18.9252 3.5582 18.4894 3.35328C18.0536 3.14836 17.5807 3.03 17.0981 3.005C16.6155 2.98 16.1326 3.04886 15.6771 3.20761C15.2217 3.36636 14.8028 3.61187 14.4444 3.93003M4.66667 9.29227C3.94836 8.65454 3.51651 7.76385 3.46543 6.81475C3.41435 5.86566 3.7482 4.9353 4.39406 4.22691C5.03992 3.51852 5.94528 3.08967 6.91239 3.03404C7.8795 2.97842 8.82975 3.30053 9.55556 3.93003M12 13.8056C11.0275 13.8056 10.0949 13.4266 9.40727 12.752C8.71964 12.0774 8.33333 11.1624 8.33333 10.2084C8.33333 9.25432 8.71964 8.33936 9.40727 7.66475C10.0949 6.99014 11.0275 6.61115 12 6.61115C12.9725 6.61115 13.9051 6.99014 14.5927 7.66475C15.2804 8.33936 15.6667 9.25432 15.6667 10.2084C15.6667 11.1624 15.2804 12.0774 14.5927 12.752C13.9051 13.4266 12.9725 13.8056 12 13.8056Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );

SvgForum.defaultProps = defaultProps;

export default memo(SvgForum);
