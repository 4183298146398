export const PRIMARY_LIGHTEST = "#E8F7F9";
export const PRIMARY_LIGHTER = "#A4DEE8";
export const PRIMARY_LIGHT = "#60C6D7";
export const PRIMARY = "#1CADC6";
export const PRIMARY_DARK = "#14798B";
export const PRIMARY_DARKER = "#0B454F";
export const PRIMARY_DARKEST = "#031114";

export const SECONDARY_LIGHTEST = "#FFF8E8";
export const SECONDARY_LIGHTER = "#FFE1A4";
export const SECONDARY_LIGHT = "#FFCB60";
export const SECONDARY = "#FFB51C";
export const SECONDARY_DARK = "#B37F14";
export const SECONDARY_DARKER = "#66480B";
export const SECONDARY_DARKEST = "#191203";

export const SUCCESS_LIGHTEST = "#D1EEDD";
export const SUCCESS_LIGHTER = "#A3DDBC";
export const SUCCESS_LIGHT = "#5DC389";
export const SUCCESS = "#18A957";
export const SUCCESS_DARK = "#11763D";
export const SUCCESS_DARKER = "#0A4423";
export const SUCCESS_DARKEST = "#052211";

export const WARNING_LIGHTEST = "#FFF1D7";
export const WARNING_LIGHTER = "#FFE4AF";
export const WARNING_LIGHT = "#FFCF74";
export const WARNING = "#FFBB38";
export const WARNING_DARK = "#B38327";
export const WARNING_DARKER = "#664B16";
export const WARNING_DARKEST = "#33250B";

export const ERROR_LIGHTEST = "#FFE6EB";
export const ERROR_LIGHTER = "#FF99AF";
export const ERROR_LIGHT = "#FF4D74";
export const ERROR = "#FF0038";
export const ERROR_DARK = "#B30027";
export const ERROR_DARKER = "#660016";
export const ERROR_DARKEST = "#190006";

export const BG_SHADES_LIGHTEST = "#CECDD0";
export const BG_SHADES_LIGHTER = "#9D9BA1";
export const BG_SHADES_LIGHT = "#6B6A73";
export const BG_SHADES = "#3A3844";
export const BG_SHADES_DARK = "#211E2C";
export const BG_SHADES_DARKER = "#161321";
export const BG_SHADES_DARKEST = "#0E0B1A";

export const GRADIENT_1 = "#35D7F3";
export const GRADIENT_2 = "#116B7A";

// export const GREY_TEXT_1 = "#817E9D";
export const GREY_TEXT_1 = "#9D9BA1";
export const GREY_TEXT_2 = "#4A485A";

export const DARK = "#090615";
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const TRANSPARENT = "transparent";
export const ANDROID_STATUS_BAR_STYLE = "light-content";
export const IOS_STATUS_BAR_STYLE = "dark-content";
export const BACKDROP_COLOR = "rgba(105, 105, 105, 0.8)";
