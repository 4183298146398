import { Box, SxProps, Theme } from "@mui/material";
import styles from "./card.module.css";
import { ReactNode } from "react";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

export type cardVariantTypes =
  | "card_1"
  | "card_2"
  | "card_3"
  | "card_4"
  | "card_5"
  | "card_6"
  | "transparent";
type Props = {
  children: ReactNode;
  sx?: SxProps<Theme>;
  onClick?: () => void;
  className?: string;
  variant?: cardVariantTypes;
};

const Card = ({
  children,
  sx,
  onClick,
  className,
  variant = "card_1",
}: Props) => {
  const boxClassName = cx("overAll", { [`${variant}`]: variant }, className);
  return (
    <Box onClick={onClick} sx={sx} className={boxClassName}>
      {children}
    </Box>
  );
};

export default Card;
