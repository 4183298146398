import { memo } from "react";

const defaultProps = {
  width: 25,
  height: 25,
};

const SvgClock = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.12891C9.51472 3.12891 7.26472 4.13627 5.63604 5.76495C4.00736 7.39362 3 9.64362 3 12.1289C3 14.6142 4.00736 16.8642 5.63604 18.4929C7.26472 20.1215 9.51472 21.1289 12 21.1289C14.4853 21.1289 16.7353 20.1215 18.364 18.4929C19.9926 16.8642 21 14.6142 21 12.1289C21 9.64362 19.9926 7.39362 18.364 5.76495C16.7353 4.13627 14.4853 3.12891 12 3.12891Z"
      stroke="#6B6A73"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8.12891L12 12.1289"
      stroke="#6B6A73"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12.1289L14 14.1289"
      stroke="#6B6A73"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

SvgClock.defaultProps = defaultProps;

export default memo(SvgClock);
