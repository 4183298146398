import { memo } from "react";

const defaultProps = {
  width: 20,
  height: 20,
};

const SvgPercentage = ({ width, height }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path
      d="M14.5859 3.7485L3.74927 14.586C3.5287 14.8066 3.40479 15.1057 3.40479 15.4177C3.40479 15.7296 3.5287 16.0288 3.74927 16.2493C3.96984 16.4699 4.269 16.5938 4.58094 16.5938C4.89288 16.5938 5.19203 16.4699 5.41261 16.2493L16.2493 5.41267C16.463 5.19068 16.5811 4.8937 16.5782 4.58557C16.5753 4.27743 16.4517 3.98272 16.2338 3.76477C16.016 3.54682 15.7213 3.42302 15.4132 3.41998C15.1051 3.41694 14.808 3.5349 14.5859 3.7485ZM5.83511 4.16684C5.61624 4.16662 5.39947 4.20951 5.19717 4.29307C4.99488 4.37662 4.81103 4.4992 4.65611 4.65381C4.50119 4.80842 4.37824 4.99203 4.29428 5.19416C4.21032 5.39628 4.16699 5.61297 4.16677 5.83184C4.16655 6.05071 4.20945 6.26748 4.293 6.46977C4.37656 6.67206 4.49914 6.85592 4.65375 7.01084C4.80836 7.16576 4.99197 7.2887 5.19409 7.37266C5.39622 7.45663 5.6129 7.49995 5.83177 7.50017C6.2738 7.50061 6.6979 7.32544 7.01077 7.01319C7.32365 6.70095 7.49966 6.2772 7.50011 5.83517C7.50055 5.39314 7.32538 4.96904 7.01313 4.65617C6.70088 4.3433 6.27713 4.16728 5.83511 4.16684ZM14.1684 12.5002C13.7264 12.4997 13.3023 12.6749 12.9894 12.9871C12.6766 13.2994 12.5005 13.7231 12.5001 14.1652C12.4997 14.6072 12.6748 15.0313 12.9871 15.3442C13.2993 15.657 13.7231 15.8331 14.1651 15.8335C14.384 15.8337 14.6007 15.7908 14.803 15.7073C15.0053 15.6237 15.1892 15.5011 15.3441 15.3465C15.499 15.1919 15.622 15.0083 15.7059 14.8062C15.7899 14.6041 15.8332 14.3874 15.8334 14.1685C15.8337 13.9496 15.7908 13.7329 15.7072 13.5306C15.6237 13.3283 15.5011 13.1444 15.3465 12.9895C15.1919 12.8346 15.0082 12.7116 14.8061 12.6277C14.604 12.5437 14.3873 12.5004 14.1684 12.5002Z"
      fill="white"
    />
  </svg>
);

SvgPercentage.defaultProps = defaultProps;

export default memo(SvgPercentage);
