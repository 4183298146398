import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { vimeoApi } from "../../../routes/apiRoutes";
import { GET_VIMEO } from "../../../redux/actions";

export const getVimeoMiddleWare = createAsyncThunk(
  GET_VIMEO,
  async ({
    page,
    pageSize,
    search,
    tags,
  }: {
    page: number;
    pageSize: number;
    search: any;
    tags?: "mailbag" | "howto" | "";
  }) => {
    try {
      const { data } = await axios.post(
        vimeoApi,
        { search, tags },
        {
          params: {
            page,
            pageSize,
          },
        }
      );
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
