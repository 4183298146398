import { memo } from "react";

const defaultProps = {
  width: 58,
  height: 58,
};

const SvgDigitalHP = ({ width, height }: typeof defaultProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7391 9.28571C20.7391 8.41429 21.0873 7.57855 21.707 6.96236C22.3266 6.34617 23.1671 6 24.0435 6H33.9565C34.8329 6 35.6734 6.34617 36.293 6.96236C36.9127 7.57855 37.2609 8.41429 37.2609 9.28571V10.9286C37.2609 11.8 36.9127 12.6357 36.293 13.2519C35.6734 13.8681 34.8329 14.2143 33.9565 14.2143H24.0435C23.1671 14.2143 22.3266 13.8681 21.707 13.2519C21.0873 12.6357 20.7391 11.8 20.7391 10.9286V9.28571ZM41.3913 9.28571H43.0435C44.358 9.28571 45.6187 9.80497 46.5483 10.7293C47.4778 11.6535 48 12.9071 48 14.2143V47.0714C48 48.3786 47.4778 49.6322 46.5483 50.5565C45.6187 51.4807 44.358 52 43.0435 52H14.9565C13.642 52 12.3813 51.4807 11.4517 50.5565C10.5222 49.6322 10 48.3786 10 47.0714V14.2143C10 12.9071 10.5222 11.6535 11.4517 10.7293C12.3813 9.80497 13.642 9.28571 14.9565 9.28571H16.6087V10.9286C16.6087 12.8893 17.392 14.7697 18.7863 16.1561C20.1806 17.5425 22.0717 18.3214 24.0435 18.3214H33.9565C35.9283 18.3214 37.8194 17.5425 39.2137 16.1561C40.608 14.7697 41.3913 12.8893 41.3913 10.9286V9.28571ZM25.5965 25.5434C25.5965 24.8304 26.1781 24.2554 26.8951 24.2554H31.1082C31.8219 24.2554 32.4035 24.8304 32.4035 25.5434V30.6691H37.5583C38.2753 30.6691 38.8536 31.2474 38.8536 31.9604V36.1497C38.8536 36.4913 38.7171 36.8189 38.4742 37.0605C38.2313 37.302 37.9018 37.4377 37.5583 37.4377H32.4035V42.5634C32.4039 42.7328 32.3707 42.9007 32.3058 43.0573C32.2409 43.214 32.1456 43.3564 32.0253 43.4763C31.9049 43.5963 31.762 43.6914 31.6046 43.7564C31.4473 43.8213 31.2786 43.8547 31.1082 43.8547H26.8918C26.7214 43.8547 26.5527 43.8213 26.3954 43.7564C26.238 43.6914 26.0951 43.5963 25.9747 43.4763C25.8544 43.3564 25.7591 43.214 25.6942 43.0573C25.6293 42.9007 25.5961 42.7328 25.5965 42.5634V37.4377H20.4417C20.0982 37.4377 19.7687 37.302 19.5258 37.0605C19.2829 36.8189 19.1464 36.4913 19.1464 36.1497V31.9571C19.1464 31.2441 19.7247 30.6659 20.4417 30.6659H25.5965V25.5434Z"
      fill="white"
    />
  </svg>
);

SvgDigitalHP.defaultProps = defaultProps;

export default memo(SvgDigitalHP);
