import { Backdrop } from "@mui/material";
import Lottie from "lottie-react";
import { memo } from "react";

type Props = {
  backgroundColor?: string;
};
const Loader = ({ backgroundColor }: Props) => {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor,
      }}
      open
    >
      <Lottie
        style={{ height: 150, width: 150 }}
        animationData={require("../../animations/loader.json")}
        loop={true}
      />
    </Backdrop>
  );
};

export default memo(Loader);
