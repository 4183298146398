import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
  fill: "white",
  focused: false,
};

const SvgTerms = ({ width, height, fill, focused }: typeof defaultProps) => (
  <svg
    opacity={focused ? 1 : 0.5}
    width={width}
    height={height}
    viewBox="0 0 489 557"
    fill="none"
  >
    <path
      d="M396.8 109.2L291.2 3.60002C288.8 1.20002 286.4 0.400024 283.2 0.400024C276.8 0.400024 272 5.20003 272 11.6V96.4C272 114 286.4 128.4 304 128.4H388.8C395.2 128.4 400 123.6 400 117.2C400 114 399.2 111.6 396.8 109.2ZM400 192.4C400 183.6 392.8 176.4 384 176.4H272C245.6 176.4 224 154.8 224 128.4V16.4C224 7.60002 216.8 0.400024 208 0.400024H48C21.6 0.400024 0 22 0 48.4V432.4C0 458.8 21.6 480.4 48 480.4C48 480.4 197.6 480.4 210.4 480.4C223.2 480.4 226.4 471.6 225.6 458C224.8 444.4 220 386 260 339.6C300 293.2 365.6 290 375.2 290C384.8 290 400.8 289.2 400 275.6C399.2 262 400 192.4 400 192.4ZM56 101.2L95.2 95.6C96 95.6 97.6 94.8 97.6 94L115.2 58C116.8 55.6 120 55.6 121.6 58L139.2 94C140 94.8 140.8 95.6 141.6 95.6L180.8 101.2C183.2 102 184.8 105.2 182.4 106.8L153.6 134.8C152.8 135.6 152.8 136.4 152.8 138L159.2 177.2C160 179.6 156.8 182 154.4 180.4L119.2 162C118.4 161.2 116.8 161.2 116 162L80.8 180.4C78.4 182 75.2 179.6 76 177.2L82.4 138C82.4 137.2 82.4 135.6 81.6 134.8L52.8 106.8C52 105.2 53.6 102 56 101.2ZM200 368.4C200 377.2 192.8 384.4 184 384.4H80C71.2 384.4 64 377.2 64 368.4V352.4C64 343.6 71.2 336.4 80 336.4H184C192.8 336.4 200 343.6 200 352.4V368.4ZM288 272.4C288 281.2 280.8 288.4 272 288.4H80C71.2 288.4 64 281.2 64 272.4V256.4C64 247.6 71.2 240.4 80 240.4H272C280.8 240.4 288 247.6 288 256.4V272.4Z"
      fill={fill}
    />
    <path
      d="M374.4 327.6C311.2 327.6 260 378.8 260 442C260 505.2 311.2 556.4 374.4 556.4C437.6 556.4 488.8 505.2 488.8 442C488.8 378.8 436.8 327.6 374.4 327.6ZM394.4 452.4C391.2 452.4 388 451.6 384.8 450.8L340.8 494C337.6 497.2 334.4 498 331.2 498C327.2 498 324.8 497.2 321.6 494C316.8 489.2 316.8 480.4 321.6 474.8L365.6 430.8C364.8 428.4 364 425.2 364 422C362.4 402 378.4 383.6 398.4 383.6C401.6 383.6 404.8 384.4 408 385.2C409.6 385.2 409.6 386.8 408.8 388.4L389.6 408.4C388 409.2 388 412.4 389.6 413.2L403.2 426.8C404.8 428.4 407.2 428.4 408.8 426.8L428 407.6C428.8 406.8 431.2 406.8 431.2 408.4C432 411.6 432.8 414.8 432.8 418C432 438 415.2 454 394.4 452.4Z"
      fill={fill}
    />
  </svg>
);

SvgTerms.defaultProps = defaultProps;

export default memo(SvgTerms);
