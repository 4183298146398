import { Box, Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ReactNode, useRef, useState, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styles from "./carousel.module.scss";

type Props = {
  children?: ReactNode;
};

const Carousel = ({ children }: Props) => {
  const elementRef = useRef<any>(null);
  const [hideArrows, setHideArrows] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Check if scrollable area is less than container width
      const element = elementRef.current;
      setHideArrows(element.scrollWidth <= element.clientWidth);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it initially
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleHorizantalScroll = (
    element: any,
    speed: any,
    distance: any,
    step: any
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      setIsEnd(
        element.scrollLeft + element.clientWidth === element.scrollWidth
      );
    }, speed);
  };

  return (
    <Box className={styles.overAll}>
      {!hideArrows && (
        <Button
          className={styles.leftArrow}
          variant="text"
          onClick={() => {
            handleHorizantalScroll(elementRef.current, 10, 400, -10);
          }}
          disabled={hideArrows}
          startIcon={<ArrowBackIosIcon />}
        />
      )}
      <Box
        className={styles.scroll}
        ref={elementRef}
        sx={{ mb: 1 }}
        display="flex"
        flexDirection={"row"}
      >
        {children}
      </Box>
      {!hideArrows && (
        <Button
          className={styles.rightArrow}
          size="small"
          variant="text"
          onClick={() => {
            handleHorizantalScroll(elementRef.current, 10, 400, 10);
          }}
          disabled={hideArrows || isEnd}
          endIcon={<ArrowForwardIosIcon />}
        />
      )}
    </Box>
  );
};

export default Carousel;
