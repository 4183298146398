import { Box } from "@mui/material";
import styles from "./centerview.module.scss";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};
const CenterView = ({ children }: Props) => {
  return (
    <Box className={styles.overAll}>
      <div className={styles.content}>{children}</div>
    </Box>
  );
};

export default CenterView;
