import { memo } from "react";

const defaultProps = {
  width: 24,
  height: 24,
  fill: "#50BDB2",
  focused: false,
};

const SvgDrAi = ({ width, height, fill, focused }: typeof defaultProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <g opacity={focused ? 1 : 0.5}>
      <path
        d="M21 11V9H19V7C18.9984 6.47005 18.7872 5.96227 18.4125 5.58753C18.0377 5.2128 17.5299 5.00158 17 5H15V3H13V5H11V3H9V5H7C6.47005 5.00158 5.96227 5.2128 5.58753 5.58753C5.2128 5.96227 5.00158 6.47005 5 7V9H3V11H5V13H3V15H5V17C5.00158 17.5299 5.2128 18.0377 5.58753 18.4125C5.96227 18.7872 6.47005 18.9984 7 19H9V21H11V19H13V21H15V19H17C17.5299 18.9984 18.0377 18.7872 18.4125 18.4125C18.7872 18.0377 18.9984 17.5299 19 17V15H21V13H19V11H21ZM17 17H7V7H17V17Z"
        fill={fill}
      />
      <path
        d="M11.3611 8H10.0161L8.0061 16H9.0331L9.4971 14.125H11.8131L12.2651 16H13.3271L11.3611 8ZM9.6321 13.324L10.6501 8.95H10.6961L11.6791 13.324H9.6321ZM14.2441 8H15.2441V16H14.2441V8Z"
        fill={fill}
      />
    </g>
  </svg>
);

SvgDrAi.defaultProps = defaultProps;

export default memo(SvgDrAi);
